const falaq=[
    {
        id:1,
        arabic:"قُلْ أَعُوذُ بِرَبِّ الْفَلَقِ",
        uzbek:"Qul a'uuzu birobbil falaq",
        tarjima:"Ayt: «Tong Robbidan panoh soʻrayman."
    },
    {
        id:2,
        arabic:" مِنْ شَرِّ مَا خَلَقَ",
        uzbek:"Min sharri maa xolaq",
        tarjima:" U yaratgan narsalar yomonligidan…"
    },
    {
        id:3,
        arabic:" وَمِنْ شَرِّ غَاسِقٍ إِذَا وَقَبَ",
        uzbek:"Va min sharri g'oosiqini izaa vaqob",
        tarjima:" va kirib kеlgan qorongʻu kеchaning yomonligidan..."
    },
    {
        id:4,
        arabic:"وَمِنْ شَرِّ النَّفَّاثَاتِ فِي الْعُقَدِ",
        uzbek:"Va min sharrin-naffaasaati fil 'uqod",
        tarjima:" va tugunlarga dam soluvchilar yomonligidan..."
    },
    {
        id:5,
        arabic:"وَمِنْ شَرِّ حَاسِدٍ إِذَا حَسَدَ",
        uzbek:"Va min sharri haasidin izaa hasad",
        tarjima:"va hasad qilgan hasadchining yomonligidan (panoh soʻrayman)»."
    }
]
export default falaq