const masad=[
    {
        id:1,
        arabic:"تَبَّتْ يَدَا أَبِي لَهَبٍ وَتَبَّ",
        uzbek:"Tabbat yadaa abii lahabiv-va tabb",
        tarjima:"Abu Lahabning ikki qoʻli halok boʻlsin, halok!"
    },
    {
        id:2,
        arabic:" مَا أَغْنَىٰ عَنْهُ مَالُهُ وَمَا كَسَبَ",
        uzbek:"Maa ag'naa 'anhu maaluhuu va maa kasab",
        tarjima:"Unga moli va kasb qilgan narsalari foyda bеrmadi."
    },
    {
        id:3,
        arabic:" سَيَصْلَىٰ نَارًا ذَاتَ لَهَبٍ",
        uzbek:"Sayaslaa naaron zaata lahab",
        tarjima:" U tеzda alangali oʻtga kirib, kuyadir."
    },
    {
        id:4,
        arabic:"وَامْرَأَتُهُ حَمَّالَةَ الْحَطَبِ",
        uzbek:"Vamroatuhu hammaalatal hatob",
        tarjima:"Va uning oʻtin koʻtargan xotini ham."
    },
    {
        id:5,
        arabic:"فِي جِيدِهَا حَبْلٌ مِنْ مَسَدٍ",
        uzbek:"Fii jiydihaa hablum mim-masad",
        tarjima:"Uning boʻynida eshilgan arqon."
    }
]
export default masad