const namoz=[
    {
        id:22,
        savol: "Namoz o'qish necha yoshdan farz bo'ladi?",
        javob: "Musulmon odamga namoz o'qish balog'atga yetishi bilan farz bo'ladi.O'g'il bolalar ehtilom bo'lishi(maniy shahvat bilan otilib chiqishi), qizlar esa hayz ko'rishi bilan balog'atga yetadi. ",
    },
    {
        id:23,
        savol: "Nafl namozi qanday namoz? ",
        javob: "'Nafl' deb farz amallardan boshqa qo'shimcha amallarga aytiladi.Ularni qilish ixtiyoriy, qilsa savob bo'ladi. Bir qancha nafl namozlari bor, masalan: tahorat qilganda shukru vuzu' namozi, quyosh yerni qizita boshlaganda zuho namozi, masjidga kirganda tahiyyatul masjid namozi, tunda tahajjud namozi o'qiladi. ",
    },
    {
        id:24,
        savol: "Juma namozini uyda o'qisa bo'ladimi?",
        javob: "Juma namozi jamoat bilan jome' masjidda o'qiladi, uyda o'qib bo'lmaydi.",
    },
    {
        id:25,
        savol: "Qazo namozlari qachon o'qiladi?",
        javob: "Uch vaqtdan - quyosh chiqayotgan, quyosh qiyomga kelgan(tik bo'lgan) va quyosh botayotgan vaqtdan boshqa vaqtda qazo namozlarini o'qishi mumkin.",
    },
    {
        id:26,
        savol: "Musofir oda safarda namozlarni qanday o'qiydi?",
        javob: "Musofir, ya'ni safarga chiqqan kishi farz namozlarini yolg'iz o'qiganda, muqim(o'sha yerda doimiy yashaydigan kishi)imomga iqtido qilmaganda qasr o'qiydi, ya'ni to'rt rakatli namozlarni ikki rak'at qilib o'qiydi.Ikki rakatli va uch rakatli namozlar qisqartirilmaydi.",
    },
    {
        id:27,
        savol: "Musofir muqimga iqtido qilganda namozni qanday o'qiydi?",
        javob: "Musofir kishi muqim imomga iqtido qilganda imomga ergashib namozni to'liq o'qiydi.",
    },
    {
        id:28,
        savol: "Shom namozining uchinchi rakatida zam sura o'qib qo'ysa nima bo'ladi?",
        javob: "Farz namzolarining avallgi ikki rak'atida zam sura o'qiladi. Agar adashib uchinchi yoki to'rtinchi rak'atda zam sura o'qib qo'ysa, sajdai sahv qilish vojib bo'ladi.Sajdai sahv oxirgi qa'dada o'tirganda tashahhudan keyin takbir aytib ikki marta sajda qilishdir.Sajdai sahvdan keyin yana bir marta tashahhud o'qib, ikki tomomga salom berish bilan namoz tugatiladi.",
    },
    {
        id:29,
        savol: "Vitr namozida qunut duosini o'qish esdan chiqib qolsa nima qiladi?",
        javob: "Bunda ham sajdai sahv qilish vojib bo'ladi.Sajdai sahv oxirgi qa'dada o'tirganda tashahhudan keyin takbir aytib ikki marta sajda qilishdir.Sajdai sahvdan keyin yana bir marta tashahhud o'qib, ikki tomomga salom berish bilan namoz tugatiladi.",
    },
    {
        id:30,
        savol: "Ayol kishi paypoq kiymasdan namoz o'qisa bo'ladmi?",
        javob: "Bo'ladi",
    },
    {
        id:31,
        savol: "Imomga iqtido qilgan kishi 'Fotiha' surasini o'qiydimi? ",
        javob: "Hanafiy mazhabida imomga iqtido qilgan kishi namozda qiroat qilmaydi, ya'ni 'Fotiha' surani o'qimaydi.Namozdagi zikrlarni va duoalrni o'z o'rnida o'qiydi. ",
    },
    {
        id:32,
        savol: "Ish kiyi bilan namoz o'qish mumkinmi?",
        javob: "Ha, faqat namoz o'qish uchun yaroqli bo'lsin. Ya'ni avratini to'liq berkitadigan, najosat tegmagan bo'lishi kerak.",
    },
    {
        id:33,
        savol: "Bomdod va peshin namozida kishi masjidga kirganda imom farz namozini o'qiyotgan bo'lsa nima qilshi kerak?",
        javob: "Bomdod namozida farz namozi tugamasdan oldin jamoatga qo'shilishga ko'zi yetsa, tezda bomdodning sunnatini o'qib oladi va jamoatga qo'shiladi.Ammo farz namozi tugaguncha ulgura olmaslikdan xavotir olsa, bomdodning sunnatini o'qimay jamoatga qo'shiladi.Agar peshin namozi bo'lsa jamoatga qo'shiladi va farz tugagandan keyin peshinning avvalgi va keyingi sunnatlarini oq'iydi. ",
    },
    {
        id:34,
        savol: "Jamoatga keyin kelib qo'shilgan kishi o'tkazib yuborilgan rak'atlarini o'qiydimi,yo'qmi?",
        javob: "Jamoatga keyin kelib qo'shilgan kishi o'tkazib yuborilgan rak'atlarni albatta o'qiydi.Imom salom berganda salom bermasdan o'rnidan turadi va ruku'sini topmagan rak'atlarini o'qiydi.Ya'ni qaysi rakatning ruku'sigacha yoki ruku'sida qo'shilgan bo'lsa, o'sha rak'atni topgan bo'ladi.Ruku'dan keyin qo'shilgan rak'atlarini boshidan o'qiydi.",
    },
    {
        id:35,
        savol: "Uxlab qolish yoki esdan chiqarish sababli o'qilmagan namzolarni nima qilish kerak?",
        javob: "Avvalo, bunday holat yuz bermasligiga harakat qilishi kerak.Ammo shunday holat yuz bersa, qazosini o'qishi kerak."
    },
    {
        id:36,
        savol: "Janoza namozini o'qish qanday amal",
        javob: "Janoza namozini o'qish bizning mazhabda farzi kifoya amal hisoblanadi.Ya'ni bir qavmdan bir guruh kishilar mayyit uchun qilinadigan zarur ishlarni qilib,janozasini o'qib dafn qilsalar, barchaga kifoya qiladi, farz ado qilingan hisoblanadi.Agar bu amalarni hech kim qilmasa, shu qavmning hammasi gunohkor bo'ladi. "
    },
    {
        id:37,
        savol: "Ba'zi kishilar tashahhud o'qiyotgan vaqtda barmog'ini ko'taradi.Nega bunday qiladi?",
        javob: "Bu amal 'ishorai sabboba' deyiladi va to'rt mazhabda ham namozda qilinadigan sunnat amallardan deb e'tibor qilingan amaldir.Buni qachon va qayerda qilinishini ustozlardan so'rab o'rganib olish yaxshi bo'ladi, kimki bu amalni qilmasa, qilganlar bilan tortishib yurmasligi lozim."
    },
    {
        id:38,
        savol: "Namozda biror amalni imomdan oldin qilib qo'ysa, namoz buziladmi?",
        javob: "Namozda imomdan oldin takbiri tahrimani aytish yoki imomdan oldin salom berish namozni buzadi.Ammo boshqa amallarni imomdan oldin qilib qo'yish namozni buzmaydi."
    },
    {
        id:39,
        savol: "Taroveh namozi qanday namoz?",
        javob: "Taroveh namozi Ramazon oyida xufton namozining farzidan keyin vitr namozidan oldin o'qiladigan nafl namozidir.Uning adadi hanafiy mazhabida 20 rak'at deyilgan.Taroveh namozini ikki rak'at-ikki rak'atdan yoki to'rt rak'at-to'rt rak'atdan o'qib, har to'rt rak'atdab so'ng biroz dam olinadi."
    },
    {
        id:40,
        savol: "Namzoda 'Fotiha' surasini o'qib bo'lganda 'Omiyn'ni ovoz chiqarib aytiladmi?",
        javob: "Ovoz chiqarmay, o'zi eshitadigan darajada 'Omiyn' deyiladi."
    },
    {
        id:41,
        savol: "Jamoat namozidan so'ng tasbeh va duolarni jamoat bilan birga qilish shartmi?",
        javob: "Har bir kishi o'zi qilshi mumkin."
    },
    {
        id:42,
        savol: "Namoz o'qiyotgan kishining oldidan odam o'tsa, namoz buziladmi?",
        javob: "Namoz buzilmaydi.Ammo o'tmaslik kerak.O'tgan odam gunohkor bo'adi."
    },
    {
        id:43,
        savol: "Namozda 'Fotiha' surasidan oldin har rak'atda 'Bismillahir rohmanir rohiym' aytiladmi?",
        javob: "Ha aytiladi. Lekin zam suradan oldin aytilmaydi."
    }
]
export default namoz