const faq = [
    {
        id: 1,
        savol: "Agarda tahoratdan sung kiyimga boshlangan spirt bulgan atir sepilsa," +
            " shu kiyimda namoz oqisa, tahorat buziladimi yoki buzilmaydimi?",
        javob: "Buzilmaydi"
    },
    {
        id:2,
        savol:'Ter chiqsa tahorat buziladmi?',
        javob:"Yo'q, ter chiqishi, tuflash kabi amallar bilan tahorat buzilmaydi."
    },
    {
        id:3,
        savol:'Qon chiqsa tahorat buziladmi?',
        javob:'Qon chiqib, bir- ikki tomchi oqishi bilan tahorat buziladi'
    },
    {
        id:4,
        savol:'Paypoqqa masx tortish mumkinmi?',
        javob:'Hanafiy mazhabiga ko\'ra, maxsi javob beradigan talablarga javob bermaydigan oddiy paypoqqa mas\'h tortish mumkin emas.'
    },
    {
        id:5,
        savol:'Uxlash bilan tahorat buziladmi?',
        javob:'Biror narsaga suyanib,yotib , yonboshlab uxlash bilan tahorat buziladi.'
    },
    {
        id:6,
        savol:'Oyoqqa maxsi kiyilgan bo\'lsa, tahorat qilganda oyoqni yuvmasa bo\'ladmi?',
        javob:'Agar oyog\'ini yuvgan holda to\'liq tahorat qilib, maxsi kiygan bo\'lsa va maxsiga mas\'h tortish muddati o\'tmagan bo\'lsa, mas\'h tortsa bo\'ladi'
    },
    {
        id:7,
        savol:"Maxsiga mas'h tortish muddati qancha?",
        javob:"Tahoratli holda maxsi kiyib, tahoratni sindirgandan boshlab muqim uchun bir kecha-yu bir kunduz, musofir uchun uch kecha-yu uch kunduz"
    },

    {
        id:8,
        savol: "Junublikdan (jinsiy yaqinlik yoki ehtilomdan) g'usl qilgan kishi tahorat qilgan kishi tahorat qilmay namoz o'qisa bo'ladmi? ",
        javob: "G'usl tahoratning o'rniga o'tadi. Junublikdan so'ng yoki oddiy holatda ham g'usl qilib, tahorat qilmay namoz o'qiyeverish mumkin.",
    },
    {
        id:9,
        savol: " Sovuq paytda junub kishi g'usl qilmasa bo'ladmi?",
        javob: "Avvalo, g'usl qilishga sharoit qilish kerak. Agar sharoit qilib bo'lmasa, junub bo'lmaslikka harakat qilish kerak. Mabodo, ehtilom bilan junub bo'lib qolsa va g'usl qilsa sog'lig'iga jiddiy zarar yetishi aniq bo'lib tursa, g'usl qilmay tayammum qilishi mumkin. So'ngra imkon bo'lishi bilan g'usl qilib oladi. Ammo sovuq sog'liqqa jiddiy zarar yetkazadigan darajada bo'lmasa, tayammum qilish mumkin emas. ",
    },
    {
        id:10,
        savol: "Tayammum nima?",
        javob: "Tayammum - suv bo'lmaganda yoki uni ishlatishga qodir bo'lmaganda tahoratning o'rniga toza tuproq bilan tahorat olishlik",
    },
    {
        id:11,
        savol: "G'usl qanday qilinadi?",
        javob: "G'usl o'giz ba burunni chayib, butun tanaga suv yetkazishdir",
    },
    {
        id:12,
        savol: "Badanga moy surtsa tahorat buziladmi?",
        javob: "Yo'q buzilmaydi.",
    },
    {
        id:13,
        savol: "Tahorat qilganda a'zolarni uch martadan ko'p yuvish mumkinmi?",
        javob: "Mumkin emas. Bu bid'at hisoblanadi.",
    },
    {
        id:14,
        savol: "Tahorat qilganda zaruratan a'zolarni uch martadan kam (1 yoki 2 marta) yuvish mumkinmi?",
        javob: "Mumkin. Hadislarda Rasululloh sollallohu alayhi vasallam ba'zan shunday qilganlari rivoyat qilingan.",
    },
    {
        id:15,
        savol: "Misvoq qilganda tishi qonaydigan kishi tahorat qilayotganda misvok qilmasa bo'ladmi? ",
        javob: "Bo'ladi. Og'izni poklash uchun oldinroq misvok qilib olsa yaxshi bo'ladi",
    },
    {
        id:16,
        savol: "Yel chiqishi bilan tahorat buziladmi? ",
        javob: "Ha , buziladi",
    },
    {
        id:17,
        savol: "Soch-saqol oldirganda tahorat buziladmi?",
        javob: "Ehtiyotsizlikdan terisini kesib olib, qon chiqsa buziladi. Qon chiqmasa buzilmaydi.",
    },
    {
        id:18,
        savol: "Badandan hid chiqishi bilan tahorat buziladmi? ",
        javob: "Agar o'sha hid yel chiqishi yoki qon chiqishi sababli emas, balki terlash kabi sabablar bilan chiqsa, tahorat buzilmaydi. ",
    },
    {
        id:19,
        savol: "So'kinish tahoratni buzadmi?",
        javob: "Buzmaydi, ammo gunoh bo'ladi.",
    },
    {
        id:20,
        savol: "Er-xotin jimo'dan keyin biri g'usl olib chiqib, ikkinchisi hali g'usl olmagan paytda bir-biriga ko'ziga tushib qolsa, poklangan kishining g'usli bekor bo'ladmi? ",
        javob: "Bekor bo'lmaydi, hech narsa qilmaydi. Bir-biriga ko'zi tusib qolishi tahorat buzilsihiga umuman aloqasi yo'q",
    },
    {
        id:21,
        savol: "Yoddan Qur'on qiroat qilish uchun tahoratli bo'lish shartmi? ",
        javob: "Shart emas, ammo tahoratli bo'lsa savobi ko'proq bo'ladi. Mus'hafni ushlash uchun tahorat bo'lsihi farz. Tahoratsiz holda yoddan o'qishi mumkin ",
    }



]
export default faq