const maun=[
    {
        id:1,
        arabic:"أَرَأَيْتَ الَّذِي يُكَذِّبُ بِالدِّينِ",
        uzbek:" A roaytal-lazii yukazzibu bid-diyn ",
        tarjima:" Jazo mukofotni yolgʻonga chiqaruvchini koʻrdingmi?"
    },
    {
        id:2,
        arabic:" فَذَٰلِكَ الَّذِي يَدُعُّ الْيَتِيمَ ",
        uzbek:" Fazaalikal-lazii yadu''ul yatiym ",
        tarjima:" Yetimni qoʻpollik bilan haydaydigan oʻshadir."
    },
    {
        id:3,
        arabic:"وَلَا يَحُضُّ عَلَىٰ طَعَامِ الْمِسْكِينِ ",
        uzbek:"Va laa yahuddu 'alaa to'aamil miskiyn",
        tarjima:"Va miskinlarga taom bеrishga qiziqtirmaydir. "
    },
    {
        id:4,
        arabic:" فَوَيْلٌ لِلْمُصَلِّينَ",
        uzbek:" Favaylul-lil-musolliyn ",
        tarjima:"Bir «namozxonlar»ga vayl boʻlsinki ..."
    },
    {
        id:5,
        arabic:"  الَّذِينَ هُمْ عَنْ صَلَاتِهِمْ سَاهُونَ",
        uzbek:" Allaziina hum 'an solaatihim saahun",
        tarjima:"ular namozlarini unutuvchilardir. "
    },
    {
        id:6,
        arabic:"الَّذِينَ هُمْ يُرَاءُونَ ",
        uzbek:"Allazina hum yuroouun",
        tarjima:" Ular riyo qiladiganlardir."
    },
    {
        id:7,
        arabic:" وَيَمْنَعُونَ الْمَاعُونَ ",
        uzbek:" Va yamna'uunal maa'un ",
        tarjima:"Va maa'uunni man qiladiganlardir."
    },
]
export default maun