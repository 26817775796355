const fatiha=[
    {
        id:1,
        arabic:"بسم الله الرحمن الرحيم",
        uzbek: "Bismillaahir rohmaanir rohiym",
        tarjima: "Mehribon va rahmli Allohning nomi bilan boshlayman "
    },
    {
        id:2,
        arabic:"الْحَمْدُ لِلَّهِ رَبِّ الْعَالَمِينَ",
        uzbek:"Alhamdu lillahi Robbil 'aalamiyn",
        tarjima:" Hamd olamlarning Robbi – Allohgadir."
    },
    {
        id:3,
        arabic:"الرَّحْمَٰنِ الرَّحِيمِ",
        uzbek:"Ar-rohmaanir rohiym",
        tarjima:"U Rohman va Rohiymdir."
    },
    {
        id:4,
        arabic:"مَالِكِ يَوْمِ الدِّينِ",
        uzbek:"Maaliki yavmid-diyn",
        tarjima:"Jazo-mukofot kunining egasidir."
    },
    {
        id:5,
        arabic:"إِيَّاكَ نَعْبُدُ وَإِيَّاكَ نَسْتَعِينُ",
        uzbek:"Iyyaaka na'budu va iyyaaka nasta'iyn",
        tarjima:"Faqat Sеngagina ibodat qilamiz va faqat Sеndangina yordam soʻraymiz."
    },
    {
        id:6,
        arabic:"اهْدِنَا الصِّرَاطَ الْمُسْتَقِيمَ",
        uzbek:"Ihdinas-sirootol mustaqiym",
        tarjima:" Bizni toʻgʻri yoʻlga hidoyat qilgin."
    },
    {
        id:7,
        arabic:"صِرَاطَ الَّذِينَ أَنْعَمْتَ عَلَيْهِمْ غَيْرِ الْمَغْضُوبِ عَلَيْهِمْ وَلَا الضَّآلِّينَ",
        uzbek:"Sirootol-laziyna an'amta 'alayhim g'oyril mag'dubi 'alayhim valad-doolliyn",
        tarjima:"Oʻzing nе'mat bеrganlarning yoʻliga: gʻazabga qolganlarnikiga ham emas, adashganlarnikiga ham emas."
    }
    // {
    //     id:7,
    //     arabic:"",
    //     uzbek:"",
    //     tarjima:""
    // }

]
export default fatiha