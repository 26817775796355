import React from 'react';
import "./sass/main.scss"
import Main from "./components/Main";

const App = () => {
    return (
        <>
         <Main/>
        </>
    );
};

export default App;