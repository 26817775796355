import React, {useState} from 'react';
import {ListGroup, ListGroupItem, Table} from "reactstrap";
import ReactAudioPlayer from 'react-audio-player';
import ReactPlayer from "react-player";
import faq from "./faq";
import namoz from "./namoz";
import fatiha from "./fatiha";
import fil from "./fil";
import quraysh from "./quraysh";
import maun from "./maun";
import kavsar from "./kavsar";
import kafirun from "./kafirun";
import nasr from "./nasr";
import masad from "./masad";
import ixlos from "./ixlos";
import falaq from "./falaq";
import nas from "./nas";
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const Main = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [style, setStyle] = useState('block')
    const [styleOne, setStyleOne] = useState('none')
    const [styleSecond, setStyleSecond] = useState('none')
    const [styleThree, setStyleThree] = useState('none')
    const [faqs, setFaqs] = useState(faq)
    const [namozs,setNamozs]=useState(namoz)
    const [fatihas,setFatihas]=useState(fatiha)
    const [fils,setFils]=useState(fil)
    const [qurayshs,setQurayshs]=useState(quraysh)
    const [mauns,setMauns]=useState(maun)
    const [kavsars,setKavsars]=useState(kavsar)
    const [kafiruns,setKafiruns]=useState(kafirun)
    const [nasrs,setNasrs]=useState(nasr)
    const [masads,setMasads]=useState(masad)
    const [ixloses,setIxloses]=useState(ixlos)
    const [falaqs,SetFalaqs]=useState(falaq)
    const [nases,setNases]=useState(nas)
    return (
        <>
            <section className="main">
                <div className="container">
                    <div className="row">
                        <div className="col-4">
                            <div className="top-image">
                                <img src="https://namoz.islom.uz/img/logonamoz.svg" alt=""/>
                            </div>
                            <ListGroup style={{display: style}}>
                                <ListGroupItem><a href="#tahorat">1.Tahorat</a></ListGroupItem>
                                <ListGroupItem><a href="#tahoratFarz">2.Tahorat farzlari </a></ListGroupItem>
                                <ListGroupItem><a href="#tahoratSunnat">3.Tahorat sunnatlari </a></ListGroupItem>
                                <ListGroupItem><a href="#tahoratMustahab">4.Tahorat mustahablari</a></ListGroupItem>
                                <ListGroupItem><a href="#tahoratMakruh">5.Tahoratdagi makruh ammalar</a></ListGroupItem>
                                <ListGroupItem><a href="#tahoratBuzish">6.Tahorat buzuvchi(mufsid)
                                    amalllar</a></ListGroupItem>
                                <ListGroupItem><a href="#tahoratDuo">7.Tahorat duoalri</a></ListGroupItem>
                                <ListGroupItem><a href="#tahoratOlish">8.Tahorat olish</a></ListGroupItem>
                                <ListGroupItem><a href="#gusl">9.G'usl</a></ListGroupItem>
                            </ListGroup>
                            <ListGroup style={{display: styleOne}}>
                                <ListGroupItem><a href="#namozQoida">1.Namoz o'qish qoidalari</a></ListGroupItem>
                                <ListGroupItem><a href="#azon">2.Azon </a></ListGroupItem>
                                <ListGroupItem><a href="#azon-duo">3.Azon duosi </a></ListGroupItem>
                                <ListGroupItem><a href="#namoz-tartib">4.Namoz tartibi</a></ListGroupItem>
                                <ListGroupItem><a href="#tasbeh">5.Tasbeh</a></ListGroupItem>
                                <ListGroupItem><a href="#oyatal-kursi">6.Oyatal Kursi</a></ListGroupItem>
                                <ListGroupItem><a href="#namozVideo">7.Namozni video orqali o'rganamiz</a></ListGroupItem>
                                <ListGroupItem><a href="#namozFarz">8.Namoz Farzlari</a></ListGroupItem>
                                <ListGroupItem><a href="#namozJoizmasAmal">9.Namozda joiz bo'lmagan
                                    amallar</a></ListGroupItem>
                                <ListGroupItem><a href="#namozBuzish">10.Namozoni buzvchi amallar </a></ListGroupItem>
                                <ListGroupItem><a href="#namozMakruh">11.Namozdgai makruh amallar </a></ListGroupItem>
                            </ListGroup>
                            <ListGroup style={{display: styleSecond}}>
                                <ListGroupItem><a href="#fatiha">1.Fotiha surasi</a></ListGroupItem>
                                <ListGroupItem><a href="#fil">2.Fil surasi</a></ListGroupItem>
                                <ListGroupItem><a href="#quraysh">3.Quraysh surasi</a></ListGroupItem>
                                <ListGroupItem><a href="#maun">4.Ma'un surasi</a></ListGroupItem>
                                <ListGroupItem><a href="#kavsar">5.Kavsar surasi</a></ListGroupItem>
                                <ListGroupItem><a href="#kafirun">6.Kafirun surasi</a></ListGroupItem>
                                <ListGroupItem><a href="#nasr">7.Nasr surasi</a></ListGroupItem>
                                <ListGroupItem><a href="#masad">8.Masad surasi</a></ListGroupItem>
                                <ListGroupItem><a href="#ixlos">9.Ixlos surasi</a></ListGroupItem>
                                <ListGroupItem><a href="#falaq">10.Falaq surasi</a></ListGroupItem>
                                <ListGroupItem><a href="#nas">11.Nas surasi</a></ListGroupItem>



                            </ListGroup>
                            <ListGroup style={{display: styleThree}}>
                                <ListGroupItem><a href="#tahoratSection">1.Tahorat haqida</a></ListGroupItem>
                                <ListGroupItem><a href="#namozSection">2.Namoz haqida </a></ListGroupItem>

                            </ListGroup>
                        </div>
                        <div className="col-8">

                            <section style={{display: style}} className="tahorat-global">
                                <div id="tahorat" className="tahorat">
                                    <h3>Tahorat</h3>
                                    <p className="text">Tahorat olishda quyidagi 4 ta asosiy qoidaga rioya qilish kerak:
                                        4
                                        ta farz, 13 ta sunnat, 8 ta tariqa va 5 ta mustahab</p>
                                </div>
                                <div id="tahoratFarz" className="farz">
                                    <h3>Tahorat farzlari</h3>
                                    <p>1. Yuzni, peshonaning soch chiqqan joyidan boshlab iyak ostigacha, ikki quloq
                                        yumshoqlari oralig‘ini yuvmoq. <br/>
                                        2. Qo‘lni tirsaklari bilan qo‘shib yuvmoq. <br/>
                                        3. Boshning to‘rtdan bir qismiga mash tortmoq. <br/>
                                        4. Oyoqlarni to‘piqlari bilan qo‘shib yuvmoq.</p>
                                </div>
                                <div id="tahoratSunnat" className="sunnat">
                                    <h3>Tahorat sunnatlari</h3>
                                    <p>1. Tahoratning avvalida qo‘lni oshiqlari bilan yuvmoq. <br/>
                                        2. Allohning nomi bilan boshlamoq (ya’ni, "Bismilloh" demoq). <br/>
                                        3. Tahoratning avvalida misvok yoki cho‘tka bilan, u ham bo‘lmasa, o‘ng qo‘l
                                        barmoqlari bilan tishlarni tozalash. <br/>
                                        4. Og‘izni uch marta chayqamoq. <br/>
                                        5. Burunga uch marta suv tortib qoqish ya’ni, istinshoq. <br/>
                                        6. Ro‘za tutmaganlar uchun yaxshilab mazmaza va istinshoq qilish. <br/>
                                        7. Qalin soqolni qo‘lni yangidan ho‘llab, barmoqlar bilan ostidan hilol
                                        qilish. <br/>
                                        8. Qo‘l va oyoq barmoqlari orasini hilol qilmoq. <br/>
                                        9. Har bir azoni uch martadan yuvmoq (birinchi yuvish farz, qolganlari
                                        sunnat). <br/>
                                        10. Boshning hammasiga bir marta mash tortish. <br/>
                                        11. Quloqlarga mash tortish. <br/>
                                        12. Azolarni ishqalab yuvish. <br/>
                                        13. Azolarni uzluksiz, ketma-ket yuvmoq. <br/>
                                        14. Niyat qilmoq. <br/>
                                    </p>
                                </div>
                                <div id="tahoratMustahab" className="mustahab">
                                    <h3>Tahorat mustahablari</h3>
                                    <p>
                                        1. Qur’oni karimda belgilangan tartibga rioya qilmoq (avval yuzni, so‘ngra ikki
                                        qo‘lni yuvgach, boshga mash tortmoq, eng oxiri oyoqlarni yuvmoq. Mazkur tartib
                                        asosida tahorat qilmoq Abu Hanifaga ko‘ra sunnat, Shofeiyga ko‘ra farz). <br/>
                                        2. Juft azolarni yuvishni o‘ngdagisidan boshlamoq. <br/>
                                        3. Qo‘l va oyoqlarni barmoq uchlaridan boshlab yuvish. <br/>
                                        4. Boshga peshonadan boshlab mash tortish, bo‘yinga mash tortish. (Halqumga mash
                                        tortish sunnat emas).
                                    </p>
                                </div>
                                <div id="tahoratMakruh" className="makruh">
                                    <h3>Tahoratdagi makruh amallar</h3>
                                    <p>
                                        1. Suvni isrof qilmoq. <br/>
                                        2. Suvni haddan ziyod (mash tortayotgandek) oz ishlatmoq. <br/>
                                        3. Suvni yuzga urmoq (sepmoq). <br/>
                                        4. Tahorat olayotganida dunyoviy so‘z aytmoq (gapirmoq). <br/>
                                        5. Uzrsiz, boshqa kishidan yordam so‘ramoq. <br/>
                                        6. Qo‘lni qayta-qayta ho‘llab, boshga uch marta mash tortish. <br/>
                                        7.Burunni o'ng qo'l bilan qoqmoq.
                                    </p>
                                </div>
                                <div id="tahoratBuzish" className="buzish">
                                    <h3>Tahoratni buzuvchi amallar</h3>
                                    <p>
                                        1. Olddan va orqadan chiqqan narsalar (najosat). <br/>
                                        2. Hech qanday qon ko‘rinmasa ham, tug‘ish. <br/>
                                        3. Old va orqadan boshqa, vujudning istalgan joyidan oqqan qon va yiring kabi
                                        narsalar. <br/>
                                        4. Xoh ovqat, xoh suv yoki quyuq qon yoki safro bo‘lsin, og‘iz to‘la qusmoq.
                                        Og‘iz
                                        to‘laligi, sahih naqlga ko‘ra, qiynalib qoladigan darajadagi to‘lalikdir. Bitta
                                        sabab tufayli qusish oz-oz bo‘lib, jami og‘izni to‘ldiradigan bo‘lsa, u ham ayni
                                        hukmdadir. <br/>
                                        5. Og‘izdan tupurgiga teng yoki undan ham ko‘p qon oqishi. <br/>
                                        6. Oyoqlar ustiga o‘tirgan holda, chalqancha yoki yuztuban yotgan holda, maqadni
                                        batamom yerga tekkizmasdan uxlamoq. <br/>
                                        7. Tamakkun etgan holda (maqadni batamom yerga tekkizib) uxlagan kishining
                                        yiqilmasa
                                        ham, uyg‘onmasdan maqadini yerdan ko‘tarishi. <br/>
                                        8. Hushidan ketmoq. <br/>
                                        9. Jinni bo‘lmoq (aqldan ozmoq). <br/>
                                        10. Mast bo‘lmoq. <br/>
                                        11. Balog‘atga yetgan va uyg‘oq kishining rukuli va sajdali holda namozda
                                        qahqaha
                                        bilan kulishi, namozdan chiqish maqsadida bo‘lsa ham. <br/>
                                        12. Erkak jinsiy a’zosi ayol jinsiy a'zosiga (ichki kiyim) kabi hech qanday
                                        to‘siqsiz tegishi tahoratni buzadi. <br/>
                                    </p>
                                </div>
                                <div id="tahoratDuo" className="tahorat-duolari">
                                    <h3>Tahorat duoalri</h3>
                                    <p>
                                        1.Tahorat olishdan avval ushbu duo o‘qiladi: <b>“A’uzu billahi minash-shaytonir
                                        rojiym.
                                        Bismillahil a’ziym. Valhamdu lillahi ‘ala diynil Islam”.</b> <br/>
                                        <i>Ulug‘ Allohning ismi bilan quvilgan shaytonning yomonligidan Allohdan panoh
                                            tilayman. Islom dini uchun Allohga hamd bo‘lsin.</i> <br/>
                                        2.Tahoratni boshlab, ikki qo‘lni yuvayotganda: <b>“Alhamdu lillahil-laziy
                                        ja’alal
                                        maa
                                        tohuran val islama nuran”.</b> <br/>
                                        <i>Suvni ham, islom dinini ham poklik manbai qilib yaratgan Allohga hamd
                                            bo‘lsin.</i> <br/>
                                        3.Og‘izni chayayotganda:<b> “Allohumma a’inniy ‘ala tilavatil qur’an va zikrika
                                        va
                                        shukrika va husni ‘ibadatik”.</b> <br/>

                                        <i>Allohim, Qur’on o‘qish, Seni zikr etish, Senga shukr qilish, chiroyli ibodat
                                            qilish
                                            uchun yordam ber.</i> <br/>
                                        4.Burunga suv tortayotganda: <b>“Allohumma arihniy roihatal jannati va la
                                        turihni
                                        roihatan-nar”.</b> <br/>
                                        <i>Allohim, meni jannat hididan bahramand et, do‘zax isidan nari tut.</i> <br/>
                                        5.Yuz yuvayotganda: <b>“Allohumma bayyiz vajhi yavma tabyazzu vujuhun va
                                        tasvaddu
                                        vujuh”.</b> <br/>

                                        <i> Allohim, yuzlar oq va qora bo‘ladigan kuni yuzimni yorug‘ qil.</i> <br/>
                                        6.O‘ng qo‘lni yuvayotganda: <b>“Allohumma a’tiniy kitabiy biya-miyniy va hasibni
                                        hisaban yasiro”.</b> <br/>

                                        <i>Allohim, qiyomat kuni amal daftarimni o‘ng tarafimdan ber va hisobimni oson
                                            qil.</i> <br/>
                                        7.Chap qo‘lni yuvayotganda: <b>“Allohumma la tu’tini kitabi bi shimali va la min
                                        varoi zohriy”.</b> <br/>

                                        <i>Allohim, amal daftarimni chap tarafimdan ham, orqadan ham bermagin.</i> <br/>
                                        8.Boshga mash tortayotganda: <b>“Allohumma azillaniy taxta zilli arshika yavma
                                        la
                                        zilla
                                        illa zillu arshik”.</b><br/>

                                        <i>Arsh soyasidan boshqa soya topilmaydigan kuni meni uning soyasi bilan
                                            soyalantir.</i><br/>
                                        9.Quloqqa mash tortayotganda:<b> “Allohummaj’alniy minallaziyna yastami’unal
                                        qovla
                                        fayattabi’una ahsanah”.</b> <br/>

                                        <i>Allohim, meni yaxshi gaplarni eshitib, unga ergashuvchi qil.</i> <br/>
                                        10.Bo‘yinga mash tortayotganda: <b>“Allohumma a’tiq raqobatiy minan
                                        nar”.</b><br/>

                                        <i>Allohim, vujudimni jahannam otashidan muhofaza qil.</i><br/>
                                        11.O‘ng oyoqni yuvayotganda: <b>“Allohumma sabbit qodamayya ‘alas siroti yavma
                                        tazillu
                                        fihil aqdam”.</b> <br/>

                                        <i>Qiyomat – oyoqlar toyguvchi kunda oyoqlarimni (Sirot ko‘prigi)dan
                                            toydirmagin.</i><br/>
                                        12.Chap oyoqni yuvayotganda: <b>“Allohummaj’al zanbiy mag‘furon va sa’yi
                                        mashkuron
                                        va
                                        tijarotiy lan taburo”.</b> <br/>

                                        <i> Allohim, gunohlarimni mag‘firat et, amallarimni go‘zal va tijoratimni
                                            zararsiz
                                            qil.</i><br/>
                                        13.Tahoratdan so‘ng: <b>“Ashhadu an la ilaha illalloh va ashhadu anna Muhammadan
                                        abduhu
                                        va rosuluh. Allohummaj’alniy minat-tavvabina vaj’alniy minal
                                        mutatohhiriyn”.</b><br/>

                                        <i>Shahodat beraman, Allohdan boshqa iloh yo‘q va Muhammad (s.a.v.) Uning
                                            bandasi va
                                            rasulidir. Allohim, meni tavba qiluvchi va poklanuvchilardan qil.</i>
                                    </p>
                                </div>
                                <div id="tahoratOlish" className="tahorat-olish">
                                    <h3>Tahorat olish</h3>
                                    <h6>1.Niyat qilish</h6>
                                    <img src="https://namoz.islom.uz/img/w1.gif" alt=""/>
                                    <p>
                                        Tahorat olish uchun, iloji bo'lsa, qiblaga yuzlaniladi.
                                        <b>"A’uzu billahi minash-shaytonir rojiym. Bismillahil a’ziym. Valhamdu lillahi
                                            ‘ala
                                            diynil Islam”</b> deb niyat qilinadi.
                                    </p>


                                    <h6>2.Qo'llarni yuvish</h6>
                                    <img src="https://namoz.islom.uz/img/w2.gif" alt=""/>
                                    <p>Qo'llar bandigacha uch marta yuviladi. Barmoqda uzuk bo'lsa, qimirlatib, ostiga
                                        suv
                                        yugurtiriladi. </p>

                                    <h6>3.Og'iz chayish</h6>
                                    <img src="https://namoz.islom.uz/img/w3.gif" alt=""/>
                                    <p>O'ng qo'lda suv olinib, og'iz uch marta g'arg'ara qilib chayiladi va misvok
                                        qilinadi. </p>

                                    <h6>4.Burun chayish</h6>
                                    <img src="https://namoz.islom.uz/img/w4.gif" alt=""/>
                                    <p>Burunga o'ng qo'l bilan uch marta suv tortilib, chap qo'l bilan qoqib
                                        tozalanadi. </p>

                                    <h6>5.Yuzni yuvish</h6>
                                    <img src="https://namoz.islom.uz/img/w5.gif" alt=""/>
                                    <p>Yuz 3 marta yuviladi. Yuzning chegarasi uzunasiga soch chiqqan joydan jag'ning
                                        ostigacha,
                                        kengligi esa ikki quloq yumshog'ining orasigacha bo'lgan o'rindir.</p>

                                    <h6>6.Qo'llarni tirsakkacha yuvish</h6>
                                    <img src="https://namoz.islom.uz/img/w6.gif" alt=""/>
                                    <p>Avval o'ng qo'l tirsaklar bilan qo'shib ishqalab yuviladi.
                                        So'ngra chap qo'l tirsaklar bilan qo'shib ishqalab yuviladi. </p>

                                    <h6>7.Mas'h tortish</h6>
                                    <img src="https://namoz.islom.uz/img/w7.gif" alt=""/>
                                    <p>Hovuchga suv olib, to'kib tashlab, ho'li bilan boshning hamma qismiga mas'h
                                        tortiladi.
                                        Ko'rsatkich barmoq bilan quloq ichiga mas'h tortilib, bosh barmoq bilan esa
                                        quloq
                                        tashqarisiga mas'h tortiladi.
                                        Ikkala kaftning orqasi bilan bo'yin mas'h qilinadi.
                                    </p>

                                    <h6>8.Oyoqlarni yuvish</h6>
                                    <img src="https://namoz.islom.uz/img/w8.gif" alt=""/>
                                    <p>Chap qo'l bilan o'ng oyoqni oshiq (to'piq) bilan qo'shib va barmoqlar orasini
                                        (ishqalab) uch marta yuviladi.Chap oyoq ham shu tarzda uch marta yuviladi. </p>
                                </div>
                                <div id="gusl" className="gusl">
                                    <h3>G'usl</h3>
                                    <p>G'usl butun baddani yuvishdir. Junub bo'lgan kishi yoki hayz va nifosdan chiqqan
                                        ayol g'usl qilmog'i farzdir.Har juma kuni, ikki hayit bayrami kunlarida , ehrom
                                        kiyilganda va arafa kuni g'usl qilmoq sunnatdir <br/>
                                        <b>G'uslning uch farzi bor:</b> <br/>

                                        1. Og‘izni halqumgacha chayish; <br/>

                                        2. Burunni achitib chayish; <br/>

                                        3. Butun badanni yuvish. <br/>

                                        G'usl tartibi quyidagicha: badanga maniy tekkan bo‘lsa, avval yuvib ketkaziladi.
                                        So‘ngra suv bilan istinjo qilinadi - oldi va orqa yo‘llar yuviladi va tahorat
                                        qilinadi. Yuvinadigan joyda suv to‘planadigan yoki oyoq loy bo‘ladigan bo‘lsa,
                                        tahoratda oyoqlar yuvilmaydi, g‘usl so‘ngida yuviladi. Keyin boshdan uch bor suv
                                        quyiladi. Bosh uch bor yuvilgach, o‘ng va chap yelkalardan ham uch martadan suv
                                        quyilib, butun a'zoi badan yuviladi. G'uslda badandagi biror tukning ham ostiga
                                        suv yetmay qolmasligi kerak. Birinchi suv quyganda badanni ishqalash sunnatdir.

                                        Qo‘shilish payti ayollarning sochi o‘rilgan bo‘lsa, yuvinayotganida uni
                                        tarqatish shart emas. Sochlarining tagiga suv yetkazilsa kifoya. Ammo sochlari
                                        o‘rilmagan holatda qo‘shilishsa, erkaklar kabi sochlarini ham to‘la yuvishlari
                                        lozim. Ayollar g‘usl paytida quloqdagi sirg‘a va qo‘llaridagi uzuklarini
                                        qimirlatib, ular o‘rniga suv yetkazishlari zarur.

                                        Agar badanga (sochga, tirnoqqa...) suv yetkazishga mone'lik qiluvchi bo‘yoq kabi
                                        narsalar surilgan bo‘lsa, g‘usldan oldin ularni ketkazish shart, aks holda g‘usl
                                        o‘rniga o‘tmaydi. Shuningdek, tishlar orasidagi ovqat qoldiqlarini ham tozalash
                                        kerak. Kindik kovagiga suv yetkazishni esdan chiqarmaslik zarur.

                                        G'uslda qiblaga yuzlanib yuvinish sunnatga xilofdir. G'usl asnosida gapirish
                                        odobsizlikdir. Avrat yopilib g‘usl qilinadi.

                                        G'usl vojib bo‘lgan kishilar Qur'oni karim tilovat qilishlari, oyatlarini
                                        yozishlari va masjidga kirishlari mumkin emas.

                                    </p>
                                </div>
                            </section>
                            <section style={{display: styleOne}} className="namoz-global">
                                <div id="namozQoida" className="namoz-qoida">
                                    <h3>Namoz o'qish qoidalari</h3>
                                    <p>Alloh taolo barcha mo'minlarga har kuni 5 vaqt - bomdod,peshin,asr,shom,xufton
                                        namozlarini
                                        o'qishni farz qildi.Har bir namoz o'z vaqtida o'qilmog'i shart. Namoz vaqtlari
                                        quyidagicha belgilangan: <br/>
                                        <b> 1.Bomdod namozi</b>-tong yorishganidan quyosh chiqquncha. <br/>
                                        <b>2.Peshin namozi</b>-quyosh tik holatdan sal og'ganidan to asr namozi kirgunga
                                        qadar. <br/>
                                        <b>3.Asr namozi</b>-odam soyasi(qiyomdagi spyasidan tashqari) ikki barobar
                                        uzayganidan to quyosh botguncha <br/>
                                        <b>4.Shom namozi</b>-quyosh botganidan to ufq qizili yo'qolguncha <br/>
                                        <b>5.Xufton namozi</b>-ufq qizili tugaganidan to tong yorishgunga qadar. <br/>
                                        Xufton namozi iloji bo'lsa, biroz kechiktirilib, ya'ni tun yarimida o'qilgani
                                        ma'qulroqdir. Har bir namoz ma'lum rakatlardan iborat. Buni quyidagi jadvalda
                                        ko'rishimz mumkin.
                                    </p>
                                    <img src="image/namoz-rakat.png" alt=""/>
                                </div>
                                <div id="azon">
                                    <h3>Azon </h3>
                                    <p>
                                        اَللهُ أَكْبَر Allohu akbar, <br/>

                                        اَللهُ أَكْبَر Allohu akbar,<br/>

                                        اَللهُ أَكْبَر Allohu akbar, <br/>

                                        اَللهُ أَكْبَر Allohu akbar, <br/>

                                        أَشْهَدُ أَلاَّ إِلَهَ إِلاَّ الله Ashhadu alla ilaha illalloh, <br/>

                                        أَشْهَدُ أَلاَّ إِلَهَ إِلاَّ الله Ashhadu alla ilaha illalloh,

                                        <br/>أَشْهَدُ أَنَّ مُحَمَّدًا رَسُولُ الله Ashhadu anna Muhammadar Rasululloh,
                                        <br/>

                                        أَشْهَدُ أَنَّ مُحَمَّدًا رَسُولُ الله Ashhadu anna Muhammadar Rasululloh, <br/>

                                        حَيَّ عَلَى الصَّلاَة Hayya 'alas - solah. <br/>

                                        حَيَّ عَلَى الصَّلاَة Hayya 'alas - solah. <br/>

                                        حَيَّ عَلَى الْفَلاَح Hayya 'alal - falah. <br/>

                                        حَيَّ عَلَى الْفَلاَح Hayya 'alal - falah. <br/>

                                        (bomdod namozida) اَلصَّلاَةُ خَيْرٌ مِنَ النَّوْم Assolatu xoyrum
                                        minannavm <br/>

                                        اَللهُ أَكْبَر Allohu akbar, <br/>

                                        اَللهُ أَكْبَر Allohu akbar, <br/>

                                        لاَ إِلَهَ إِلاَّ الله La ilaha illalloh. <br/>
                                    </p>
                                    <p>
                                        <b>Tarjimasi:</b> <br/>
                                        Alloh buyukdir! <br/>
                                        Alloh buyukdir! <br/>
                                        Alloh buyukdir! <br/>
                                        Alloh buyukdir! <br/>

                                        Allohdan o'zga sig'iniladigan (iloh) yo'qligiga iqrorman! <br/>
                                        Allohdan o'zga sig'iniladigan (iloh) yo'qligiga iqrorman! <br/>
                                        Muhammad Allohning elchisi ekaniga iqrorman! <br/>
                                        Muhammad Allohning elchisi ekaniga iqrorman! <br/>

                                        Namozga keling, namozga keling! <br/>
                                        Najotga keling, najotga keling! <br/>
                                        (Namoz uyqudan afzaldir) <br/>
                                        Alloh buyukdir! <br/>
                                        Alloh buyukdir! <br/>

                                        Allohdan o'zga iloh yo'q!</p>
                                    <ReactAudioPlayer
                                        src='audio/azan.mp3'
                                        autoPlay
                                        controls
                                    />
                                </div>
                                <div id="azon-duo">
                                    <h3>Azon duosi</h3>
                                    <p>عَنْ جَابِرٍ رَضِي اللهُ عَنْهُ أَنَّ رَسُولَ اللهِ صَلَي الله عَلَيْهِ وَسَلَّم
                                        قَالَ: مَنْ قَالَ حِينَ يَسْمَعُ النِّدَاءَ اللَّهُمَّ رَبَّ هَذِهِ الدَّعْوَةِ
                                        التَّامَّةِ، وَالصَّلَاةِ الْقَائِمَةِ آتِ مُحَمَّدًا الْوَسِيلَةَ
                                        وَالْفَضِيلَةَ، وَابْعَثْهُ مَقَامًا مَحْمُودًا الَّذِي وَعَدْتَهُ، حَلَّتْ لَهُ
                                        شَفَاعَتِي يَوْمَ الْقِيَامَةِ. رَوَاهُ الْخَمْسَةُ إِلَّا مُسْلِمًا</p>
                                    <p>Allohumma robba hazihid-da'vatit-tammah. Vas-solatil qoimah, ati Muhammadanil
                                        vasiylata val faziylah. Vab'ashu maqomam mahmudanillaziy va'adtah. Varzuqna
                                        shafa-'atahu yavmal qiyamah. Innaka la tuxliful mi'ad!</p>
                                    <p><b>Ma'nosi:</b> Ushbu komil da'vatning, hozir qoim bo'lgan namozning
                                        Parvardigori, ey
                                        Alloh! Muhammadga (s.a.v.) vasila, fazilat va baland oliy daraja ato etgin. Uni
                                        O'zing va'da qilgan maqtovli maqomda tiriltirgin. Bizga Qiyomat kunida uning
                                        shafoatini nasib et. Albatta, Sen va'daga xilof qilmassan.</p>
                                </div>
                                <div id="namoz-tartib">
                                    <h3>Namoz tartibi</h3>

                                    <h6>1.Niyat qilish</h6>
                                    <img src="https://namoz.islom.uz/img/img1.png" alt=""/>
                                    <p>Namoz vaqti kirgach, tahorat bilan, pok kiyim bilan, pokiza joyda turib, qiblaga
                                        yuzlanamiz va niyat qilamiz. Masalan, <b>"Xolis Alloh uchun bomdod namozining
                                            ikki
                                            rakat farzini o'qishga niyat qildim"</b> degan mazmunda niyat qilamiz.
                                        Imomga iqtido
                                        qilgan kishi
                                        <b> " shu imomga iqtido qilib "</b> deb ham niyat qilamiz. Niyatni pichirlab,
                                        tilda ham
                                        ichimizda ham aytish mumkin.
                                    </p>


                                    <h6>2.Takbiri tahrima</h6>
                                    <img src="https://namoz.islom.uz/img/2.gif" alt=""/>
                                    <p><b>"Allohu Akbar"</b>, deb iftitoh takbiri aytiladi.Bu takbirda erkaklar qo'llar
                                        kaftini
                                        qiblaga qaratib, bosh barmoqlarini quloqlarining yumshoq joyiga tekkishishadi
                                    </p>
                                    <div className="must">
                                        <div><h5><b>Allohu Akbar</b></h5>
                                            <h5>Alloh Buyukdir</h5>
                                        </div>
                                        <h1>اللَّهُ اكْبَرُ</h1>
                                    </div>

                                    <h6>3.Qiyom</h6>
                                    <img src="https://namoz.islom.uz/last/img3.png" alt=""/>
                                    <p>Qo'llar bog'lanadi. Erkaklar o'ng qo'l kaftini chap qo'l ustiga qo'yishadi. O'ng
                                        qo'lining
                                        bosh va kichik barmoqlari bilan chap qo'l bilagini ushlashadi.Qiyomda (tik
                                        turgan holda)
                                        sajda qilinadigan joyga qarab, navbati bilan <b>sano</b>, <b>"Fotiha"</b> va <b>zam
                                            sura </b>
                                        o'qiladi.Agar imomga iqtido qilgan bo'lsa <b>sano</b>dan boshqa narsa aytmaydi.
                                    </p>
                                    <h3>Sano duosi</h3>
                                    <div className="must">
                                        <div>
                                            <h5><b>Subhanakallouhumma va bihamdika va tabarokasmuka va taa’la jadduka va
                                                la ilaha g‘oyruk.</b></h5>
                                            <h5>Ma'nosi: «Allohim! Sening noming muborakdir. Shon-sharafing ulug'dir.
                                                Sendan o'zga iloh yo'qdir».</h5>
                                        </div>
                                        <div>
                                            <h1>
                                                سُبْحَانَكَ اللَّهُمَّ وَبِحَمْدِكَ، وَتَبَارَكَ
                                                اسْمُكَ، وَتَعَالَى جَدُّكَ، وَلَا إِلَهَ
                                                غَيْرُكَ.
                                            </h1>
                                        </div>
                                    </div>
                                    <ReactAudioPlayer
                                        src='audio/sano.mp3'
                                        autoPlay
                                        controls
                                    />

                                    <h3>Fotiha surasi</h3>
                                    <div className="must">
                                        <div>
                                            <h5><b>
                                                A’uzu billahi minash shaytonir rojiym. Bismillahir rohmanir rohiym. Al
                                                hamdulillahi robbil ‘alamiyn. Ar-rohmanir rohiym. Maliki yavmiddiyn.
                                                Iyyaka na’budu va iyyaka nasta’iyn. Ihdinas sirotol mustaqiym.
                                                Sirotollaziyna an’amta ‘alayhim g‘oyril mag‘zubi ‘alayhim valaddolliyn.
                                            </b></h5>
                                            <h5>
                                                Mazmuni: Allohning dargohidan quvilgan shayton yomonligidan Allohning
                                                panohiga qochaman. Mehribon va rahmli Alloh nomi bilan (boshlayman).
                                                Hamd olamlar rabbi Allohgakim, (U) mehribon, rahmli va hisob-kitob kuni
                                                (Qiyomat)ning egasidir. Sengagina ibodat qilamiz va Sendangina yordam
                                                so'raymiz! Bizni shunday to'g'ri yo'lga boshlaginki, (U) Sen in'om
                                                (hidoyat) etganlarning (payg'ambarlar, siddiq va shahidlarning)
                                                yo'lidir, g'azabga uchragan (Muso qavmidan itoatsizlarining) va adashgan
                                                (Iso qavmining «Allohning farzandi bor» deydigan)larning emas!
                                            </h5>
                                        </div>
                                        <div>
                                            <h1>
                                                بِسْمِ اللَّهِ الرَّحْمَٰنِ الرَّحِيمِ
                                                الْحَمْدُ لِلَّهِ رَبِّ الْعَالَمِينَ
                                                الرَّحْمَٰنِ الرَّحِيمِ
                                                مَالِكِ يَوْمِ الدِّينِ
                                                إِيَّاكَ نَعْبُدُ وَإِيَّاكَ نَسْتَعِينُ
                                                اهْدِنَا الصِّرَاطَ الْمُسْتَقِيمَ
                                                صِرَاطَ الَّذِينَ أَنْعَمْتَ عَلَيْهِمْ غَيْرِ الْمَغْضُوبِ عَلَيْهِمْ
                                                وَلَا الضَّالِّينَ
                                            </h1>
                                        </div>
                                    </div>
                                    <ReactAudioPlayer
                                        src='audio/fatiha.mp3'
                                        autoPlay
                                        controls
                                    />

                                    <p>Sunnat, nafl, vojib namozlarining har rakatida, farz namozlarining esa birinchi
                                        ikki rakatida Fotiha surasidan so'ng zam sura, ya'ni biror sura yoki uzunroq
                                        oyat yoki kamida uchta qisqa oyat o'qiladi. Farz namozlarining uchinchi va
                                        to'rtinchi
                                        rakatida Fotiha surasidan keyin zam sura o'qilmaydi</p>
                                    <h3>AN-NOS surasi</h3>
                                    <div className="must">
                                        <div>
                                            <h5><b>
                                                Qul a’uzu birrobin-nas malikin-nas ilahin-nas min sharril vasvasil
                                                xonnas. Allaziy yuvasvisu fiy sudurin-nas minal jinnati van-nas.
                                            </b></h5>
                                            <h5 className="mt-3">
                                                Ma'nosi: «(Ey Muhammad,) ayting: "Panoh tilab iltijo qilurman odamlar
                                                Parvardigoriga, odamlar Podshohiga, odamlar Ilohiga yashirin vasvasachi
                                                (shayton) yovuzligidanki, (u) odamlarning dillariga vasvasa solur.
                                                (O'zi) jinlar va odamlardandir"».
                                            </h5>
                                        </div>
                                        <div>
                                            <h1>
                                                قُلْ أَعُوذُ بِرَبِّ النَّاسِ
                                                مَلِكِ النَّاسِ
                                                إِلَٰهِ النَّاسِ
                                                مِنْ شَرِّ الْوَسْوَاسِ الْخَنَّاسِ
                                                الَّذِي يُوَسْوِسُ فِي صُدُورِ النَّاسِ
                                                مِنَ الْجِنَّةِ وَالنَّاسِ
                                            </h1>
                                        </div>
                                    </div>
                                    <ReactAudioPlayer
                                        src='audio/nas.mp3'
                                        autoPlay
                                        controls
                                    />
                                    <h3>Ixlos surasi</h3>
                                    <div className="must">
                                        <div>
                                            <h5><b>Qul huvalloohu ahad <br/>
                                                Alloohus-somad <br/> Lam yalid va lam yuulad. <br/> Va lam yakullahu
                                                kufuan ahad
                                            </b></h5>
                                            <h5>Mazmuni: (Ey Muhammad,) ayting: «U — Alloh yagonadir. Alloh behojat,
                                                (lekin) hojatbarordir. U tug'magan va tug'ilmagan ham. Shuningdek, Unga
                                                teng biror zot yo'qdir».</h5>
                                        </div>
                                        <div>
                                            <h1>قُلْ هُوَ اللَّهُ أَحَدٌ
                                                اللَّهُ الصَّمَدُ
                                                لَمْ يَلِدْ وَلَمْ يُولَدْ
                                                وَلَمْ يَكُنْ لَهُ كُفُوًا أَحَدٌ</h1>
                                        </div>
                                    </div>
                                    <ReactAudioPlayer
                                        src='audio/ixlas.mp3'
                                        autoPlay
                                        controls
                                    />
                                    <h6>4.Ruku</h6>
                                    <img src="https://namoz.islom.uz/img/3.gif" alt=""/>
                                    <p>Zam sura tugagach, <b>"Allohu Akbar"</b>, deb ruku qilinadi.
                                        Erkaklar tirsaklari va tizzalarini bukmasdan, barmoqlarini ochgan xolda,
                                        qo'llari bilan tizzalarini
                                        mahkam ushlab egiladilar.Rukuda uch marta <b>"Subhana robbiyal 'aziym"</b>,
                                        deyiladi</p>
                                    <div className="must">
                                        <div>
                                            <h5><b>Subhana robbiyal 'aziym</b></h5>
                                            <h5>Ey buyuk Robbim, seni barcha nuqsonlardan poklab yod etaman</h5>
                                        </div>
                                        <div>
                                            <h1>ﺳُﺒْﺤَﺎﻥَ ﺭَﺑِّﻲَ ﺍﻟﻌَﻈﻴﻢِ</h1>
                                        </div>
                                    </div>

                                    <h6>5.Qiyom</h6>
                                    <img src="https://namoz.islom.uz/img/6.gif" alt=""/>
                                    <p>Keyin namozxon bir o'zi bo'lsa, <b>"Sami'allohu liman hamidah"</b>ni aytib,
                                        tiklanib bo'lganidan keyin <b>"Robbanaa lakal hamd"</b> deydi. Jamoat namozida
                                        esa, imom faqat <b>"Sami'allohu liman hamidah"</b>ni aytadi, iqtido qiluvchi esa
                                        <b>"Robbanaa lakal hamd"</b> deydi.
                                    </p>
                                    <div className="must">
                                        <div>
                                            <h5><b>Sami'allohu liman hamidah</b></h5>
                                            <h5>Alloh Uni hamd etganlarni eshitguvchidir</h5>
                                        </div>
                                        <div>
                                            <h1>
                                                ﺳَﻤِﻊَ اللَّهُ ﻟِﻤَﻦْ ﺣَﻤِﺪَﻩُ
                                                ﺭَﺑَّﻨَﺎ وَ ﻟَﻚَ ﺍﻟْﺤَﻤْﺪُ
                                            </h1>
                                        </div>
                                    </div>

                                    <h6>6.Sajda</h6>
                                    <img src="https://namoz.islom.uz/img/7.gif" alt=""/>
                                    <p><b>"Allohu Akbar"</b> deb avvalo tizzalar, keyin qo'llar, so'ng peshona va burun
                                        tekkizilib,
                                        sajda qiliniladi.</p>
                                    <img src="https://namoz.islom.uz/img/img7.gif" alt=""/>
                                    <p>Sajda qilayatganda oyoq panjalari qiblaga qaratiladi, erkaklarning
                                        tirsaklari biqinlarigacha va yerga tegmaydi. Sajdada uch marta <b>"Subhana
                                            robbiyal a'la"</b> deyiladi</p>
                                    <div className="must">
                                        <div>
                                            <h5><b>Subhana robbiyal a'la</b></h5>
                                            <h5>Ey ulug' Robbim, Sen butun nuqsonlardan poksan</h5>
                                        </div>
                                        <div>
                                            <h1>سُبْحَانَ رَبِّيَ الأأعْلَي</h1>
                                        </div>
                                    </div>

                                    <h6>7.Jalsa</h6>
                                    <img src="https://namoz.islom.uz/img/8.gif" alt=""/>
                                    <p><b>"Allohu Akbar"</b>, deb sajdadan bosh ko'tariladi va tiz cho'kkan holda biroz
                                        o'tiriladi. O'tirganda qo'llar, barmoqlar o'z holicha tutilib, songa qo'yiladi.
                                        Barmoq uchlari tizza bilan teng bo'lishi lozim</p>


                                    <h6>8.Sajda</h6>
                                    <img src="https://namoz.islom.uz/img/9.gif" alt=""/>
                                    <p><b>"Allohu Akbar"</b> deb ikkinchi marta sajda qilinadi. Sajdada kamida uch marta
                                        "Subhana robbiyal a'la" deyiladi.</p>

                                    <h6>9.Qiyom</h6>
                                    <img src="https://namoz.islom.uz/img/10.gif" alt=""/>
                                    <p>"Allohu Akbar" deb qiyomga turiladi.Sajdadan turishdan avval boshni keyin
                                        qo'llarni
                                        keyin esa tizzani ko'taramiz</p>

                                    <h3>Ikkinchi rakat</h3>
                                    <img src="https://namoz.islom.uz/img/5.gif" alt=""/>
                                    Фотиҳани ўқиб, сўнг зам сурани ўқиб, такбир
                                    айтиб рукуъга эгиламиз. Кейин туриб, такбир айтиб, икки марта сажда қиламиз.
                                    <p>Ikkinchi rakat ham huddi birinchi rakatga o'xshab o'qiladi. Faqat
                                        boshida <b>takbiri tahrima</b>, <b>sano</b> va <b>"a'uzu"</b> aytilmaydi.
                                        Fotihani o'qib, so'ng zam surani o'qib, takbir aytib rukuga egilamiz.
                                        Keyin turib, takbir aytib ikki marta sajda qilamiz. </p>

                                    <h6>10.Qa'da</h6>
                                    <img src="https://namoz.islom.uz/img/11.gif" alt=""/>
                                    <p>Ikkinchi rak'atning ikkinchi sajdasini qilib bo'lgandan keyin chap oyoq ustiga
                                        o'tirib
                                        qiblaga qarab o'tiriladi. O'ng qo'lini o'ng soni ustiga, chap qo'lini esa chap
                                        soni ustiga barmoqlarini
                                        yoygan xolda qo'yadi.Keyin tashahhud o'qiydi</p>

                                    <h3>Tashahhud</h3>
                                    <div className="must">
                                        <div>
                                            <h5><b>Attahiyyatu lillahi vas-solavatu vattoyyibat. Assalamu ‘alayka
                                                ayyuhan-nabiyyu va rohmatullohi va barokatuh. Assalamu ‘alayna va ‘ala
                                                ibadillahis-solihiyn. Ashhadu alla ilaha illallohu va ashhadu anna
                                                Muhammadan ‘abduhu va rosuluh.</b></h5>
                                            <h5>
                                                Mazmuni: Mol, badan, til bilan ado etiladigan butun ibodatlar Alloh
                                                uchundir. Ey Nabiy! Allohning rahmati va barakoti Sizga bo'lsin. Sizga
                                                va solih qullarga Allohning salomi bo'lsin. Iqrormanki, Allohdan o'zga
                                                iloh yo'q. Va yana iqrormanki, Muhammad, alayhissalom, Allohning quli va
                                                elchisidirlar.
                                            </h5>
                                        </div>
                                        <div>
                                            <h1>
                                                التَّحِيَّاتُ لِلَّهِ وَالصَّلَوَاتُ
                                                وَالطَّيِّبَاتُ، السَّلَامُ عَلَيْكَ أَيُّهَا
                                                النَّبِيُّ وَرَحْمَةُ اللهِ وَبَرَكَاتُهُ، السَّلَامُ
                                                عَلَيْنَا وَعَلَى عِبَادِ اللهِ الصَّالِحِينَ،
                                                أَشْهَدُ أَنْ لَا إِلَهَ إِلَّا اللهُ، وَأَشْهَدُ
                                                أَنَّ مُحَمَّدًا عَبْدُهُ وَرَسُولُهُ.
                                            </h1>
                                        </div>
                                    </div>
                                    <p>Agar namoz ikki rakatli bo'lsa tashahuddan so'ng ikki salovatlarni va duoni
                                        o'qiydi
                                        Avval o'ng, keyin chap yelkaga qarab <b>"Assalomu alaykum va rohmatulloh"</b>,
                                        deb salom berilib
                                        namozdan chiqiladi</p>
                                    <h3>Ikki salovat</h3>
                                    <div className="must">
                                        <div>
                                            <h5><b>Allohumma solli ‘ala Muhammadiv-va ‘ala ali Muhammad. Kama sollayta
                                                ‘ala Ibrohima va ‘ala ali Ibrohim. Innaka hamidum-majid. Allohumma barik
                                                ‘ala Muhammadiv-va ‘ala ali Muhammad. Kama barokta ‘ala Ibrohima va ‘ala
                                                ali Ibrohim. Innaka hamidum-majid.</b></h5>
                                            <h5>Mazmuni: Allohim, Ibrohim va uning oilasiga rahmat etganing kabi,
                                                Muhammad va ul zotning oilasiga rahmat ayla, Sen hamdu maqtovga loyiq va
                                                buyuk Zotsan.
                                                Allohim, Ibrohim va uning oilasiga barakotingni ehson etganing kabi
                                                Muhammad va ul zotning oilasi ustiga ham barakotingni ehson ayla. Sen
                                                hamdu maqtovga loyiq va buyuk Zotsan.</h5>
                                        </div>
                                        <div>
                                            <h1>
                                                اللَّهُمَّ صَلِّ عَلَى مُحَمَّدٍ وَعَلَى آلِ
                                                مُحَمَّدٍ، كَمَا صَلَّيْتَ عَلَى إِبْرَاهِيمَ
                                                وَعَلَى آلِ إِبْرَاهِيمَ، إِنَّكَ حَمِيدٌ
                                                مَجِيدٌ. اللَّهُمَّ بَارِكْ عَلى مُحَمَّدٍ وَعَلَى
                                                آلِ مُحَمَّدٍ، كَمَا بَارَكْتَ عَلَى إِبْرَاهِيمَ
                                                وَعَلَى آلِ إِبْرَاهِيمَ إِنَّكَ حَمِيدٌ مَجِيدٌ.
                                            </h1>
                                        </div>
                                    </div>
                                    <h3>Duo</h3>
                                    <div className="must">
                                        <div>
                                            <h5><b>Allumag'firliy va li validayya vali jamiy'il mu'miniyina val mu'minat
                                                val muslimiyna val muslimat.</b></h5>
                                        </div>
                                        <div>
                                            <h5>Mazmuni: «Allohim, meni, ota-onamni, barcha mo'min-mo'minalarni hamda
                                                muslim va muslimalarni mag'rifat qil».</h5>
                                        </div>
                                    </div>
                                    <h3>Salom berish</h3>
                                    <img src="https://namoz.islom.uz/img/1.gif" alt=""/>
                                    <div className="must">
                                        <div>
                                            <h5><b>Assalomu alaykum va rohmatulloh</b></h5>
                                            <h5>Sizga tinchlik omonlik, Allohning rahmati bo'lsin</h5>
                                        </div>
                                        <div>
                                            <h1>ﺍﻟﺴَّﻼﻡُ ﻋَﻠَﻴْﻜُﻢ ﻭَﺭَﺣْﻤَﺔُ اللَّهِ</h1>
                                        </div>
                                    </div>
                                    <h3>Duo(Salom bergandan so'ng)</h3>
                                    <div className="must">
                                        <div><h5><b>Robbana atina fid-dunya hasanatav-va fil axiroti hasanatav-va qina
                                            azaban-nar.</b></h5></div>
                                        <div><h5>Mazmuni: «Ey Robbimiz, bizga bu dunyoda ham, oxiratda ham yaxshilikni
                                            bergin va bizni do'zax olovi azobidan saqlagin».</h5></div>
                                    </div>
                                    <div className="must">
                                        <h5><b>Allohuma antas salam va minkas salam, tabarokta ya zal jalali val
                                            ikrom</b>(Shu bilan namoz tugaydi)</h5>
                                    </div>
                                    <p>Agar namoz uch yoki to'rt rakatli bo'lsa, ikki rakatning tashahhudini o'qib
                                        bo'lib, keyin uchinchi rakatga turamiz
                                        va huddi ikkinchi rakatga o'xshatib, namozni davom ettiramiz.To'rtinchi
                                        rakatning ikkinchi sajdasidan so'ng o'tirib,
                                        tashahhud, salovat va duolarni o'qib, ikki tomonga salom beramiz</p>

                                </div>
                                <div id="tasbeh">
                                    <h3>Tasbehlar</h3>
                                    <div className="must">
                                        <div><img className="w-75"
                                                  src="https://images-na.ssl-images-amazon.com/images/I/71TpvOpUFbL.jpg"
                                                  alt=""/></div>
                                        <div>
                                            <h4>"Subhanalloh"</h4>
                                            <h4>"Alhamdulillah"</h4>
                                            <h4>"Allohu Akbar"</h4>
                                            <p>Har biri 33 martada aytiladi. Shoshilinch paytda 10 martadan aytish
                                                kifoya bo'ladi.</p>
                                        </div>
                                    </div>
                                </div>
                                <div id="oyatal-kursi">
                                    <h3>Oyatal kursi</h3>
                                    <p>Namozdan keyin <b>"Oyatal Kursiy"</b>ni o'qish ham savobli</p>
                                    <div className="must">
                                        <div>
                                            <h5><b>Allohu la ilaha illa huval hayyul qoyyum. La ta'xuzuhu sinatuv vala
                                                navm. Lahu ma fis-samavati va ma fil arz. Man zallaziy yashfa'u 'indahu
                                                illa bi iznih. Ya'lamu ma bayna aydihim va ma xolfahum va la yuhituna
                                                bishay'im
                                                min 'ilmihi illa bima shaa vasi'a kursiyyuhus samavati val-arz. Vala
                                                yauduhu hifzuhumma
                                                va huval 'aliyyul 'aziym</b></h5>
                                        </div>
                                        <div>
                                            <h5>Alloh- Undan o'zga iloj yo'qdir.(U) hamisha Barhayot va abadiy
                                                Turuvchidir.
                                                Uni na mudroq tutar va na uyqu. Osmonlar va Yerdagi(barcha) narsalar
                                                Unikidir.
                                                (Qiyomat kuni) Uning huzurida kim ham (gunohkorlarni) Uning ruxsatisz
                                                shafoat qila oladri?!
                                                (U) ulardan (odamlardan) oldingi va keyingi narsalarni bilur.(Odamlar)
                                                Uning ilmidan faqat
                                                (U)istagan miqdoricha o'zlashtirurlar. Uning Kursiysi osmonlar va
                                                yerni(ham) o'z ichiga
                                                sig'dira olur. U ikkisini muhofaza etish Uni toliqtirmas. U Oliy va
                                                Buyukdir.
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div id="namozVideo">
                                    <h6 className="mt-3 mb-3">1.Bomdod namozi to'liq video</h6>
                                    <div className="video">
                                        <ReactPlayer
                                            url="https://www.youtube.com/watch?v=RnKtl_mEvEI&t=656s"
                                        />
                                    </div>
                                    <h6 className="mt-3 mb-3">2.Peshin namozi to'liq video</h6>
                                    <div className="video">
                                        <ReactPlayer
                                            url="https://www.youtube.com/watch?v=KEnDKj9jo-0"
                                        />
                                    </div>
                                    <h6 className="mt-3 mb-3">3.Asr namozi to'liq video</h6>
                                    <div className="video">
                                        <ReactPlayer
                                            url="https://www.youtube.com/watch?v=B6RxssjqayI"
                                        />
                                    </div>
                                    <h6 className="mt-3 mb-3">4.Shom namozi to'liq video</h6>
                                    <div className="video">
                                        <ReactPlayer
                                            url="https://www.youtube.com/watch?v=Zvvk1-BXkkk"
                                        />
                                    </div>
                                    <h6 className="mt-3 mb-3">5.Xufton namozi to'liq video</h6>
                                    <div className="video">
                                        <ReactPlayer
                                            url="https://www.youtube.com/watch?v=UWo-XEu_Mbo"
                                        />
                                    </div>
                                    <h6 className="mt-3 mb-3">Juma namozi to'liq video</h6>
                                    <div className="video">
                                        <ReactPlayer
                                            url="https://www.youtube.com/watch?v=3xNfVw9RWpQ&t=778s"
                                        />
                                    </div>
                                    <h6 className="mt-3 mb-3">Hayit namozi to'liq video</h6>
                                   <div className="video">
                                       <ReactPlayer
                                           url="https://www.youtube.com/watch?v=9VR7qyx9Tx4"
                                       />
                                   </div>
                                </div>
                                <div id="namozFarz">
                                    <h3>Namoz Farzlari</h3>
                                    <p>Namozning xilofsiz farzlari o'n ikkitadan iborat. Bularning ba'zilari namozdadan
                                        tashqarida bo'lib, bular namozning
                                        shartlari hisoblanadi, ba'zilari namozning ichida bo'lib, namozning ruknlari deb
                                        ataladi.</p>
                                    <h6>1.Namozning shartlari oltitadir:</h6>
                                    <p>
                                        1.Tan pok bo'lmog'i, ya'ni tahoratli bo'lishi. <br/>
                                        2.Badan, kiyim va namoz o'qiladigan joy tozza bo'lishi. <br/>
                                        3.Avrat yopiq bo'lishi. <br/>
                                        4.Qiblaga qarab o'qimoq. <br/>
                                        5.Namozni o'z vaqtida o'qimoq. <br/>
                                        6.Dil bilan niyat qilmoq.
                                    </p>
                                    <h6>2.Namoz ichidagi farzlar, ya'ni ruknlar ham oltita:</h6>
                                    <p>
                                        1.Namozga <b>"Allohu Akbar"</b> deb takbir bilan kirmoq<br/>
                                        2.Qiyom(tik turmoq) <br/>
                                        3.Qiyomda turib, 3 oyatdan kam bo'lmagan holda Qur'on o'qimoq <br/>
                                        4.Ruku qilmoq(qo'llarni tizzaga qo'yib egilishi) <br/>
                                        5.Sajda qilmoq <br/>
                                        6.Namoz oxirida <b>"At-tahiyyot"</b>ni o'qish miqdoricha o'tirmoq.
                                    </p>
                                </div>
                                <div id="namozJoizmasAmal">
                                    <h3>Namozda joiz bo'lmagan amallar</h3>
                                    <p>
                                        1.<b>"Bismillahir Rohmanir Rohiym"</b> va <b>"Omin"</b>ni ovoz chiqarib aytmoq
                                        (hanafiy mazhabida). <br/>
                                        2.O'ng va chap tarafga yuz o'girib qaramoq. <br/>
                                        3.Osmonga qaramoq. <br/>
                                        4.Farz namozlarida uzrsiz bir narsaga suyanmoq. <br/>
                                        5.Qo'l ko'tarish sunnat bo'lmagan takbirlarda qo'l ko'tarmoq. <br/>
                                        6.Sajdada qo'l va oyoq barmoqlarini yerdan ko'tarmoq. <br/>
                                        7.Qa'dada ikki oyoqni tik qilib, tovon ustida o'tirmoq. <br/>
                                        8.Badanni yoki kiyimni uch martadan kam o'ynamoq (uch marta o'ynasa, namoz
                                        buziladi) <br/>
                                        9.Salomni faqat bir tarafga bermoq. <br/>
                                        10.Vitr namozidan boshqa namozda <b>"Qunut duosi"</b>ni o'qimoq <br/>
                                        11.Takbir, sano, tasbeh va tashahhudlarni sunnat miqdoridan oshirmoq. <br/>
                                        12.Namoz vojiblaridan birini qasddan tark etmoq.
                                    </p>
                                </div>
                                <div id="namozBuzish">
                                    <h3>Namozni buzuvchi amallar</h3>
                                    <p>
                                        1. Namozda gapirish (qasddan yoki adashib bo‘lsa ham). <br/>

                                        2. Kishiga salom berish. <br/>

                                        3. Salomga alik olish <br/>.

                                        4. Uzrsiz tomoq qirish, yo‘talish. <br/>

                                        5. «Uh, uf, oh» kabi tovushlar chiqarish. <br/>

                                        6. Og‘riq yoki musibatga ovoz chiqarib yig‘lash (ammo oxiratni o‘ylab yig‘lasa
                                        joiz). <br/>

                                        7. O‘zi eshitadigan darajada kulish (qahqaha bilan qattiq kulsa, tahorati ham
                                        ketadi). <br/>

                                        8. Aksa urganida javob qaytarish (ya’ni «Yarhamukalloh» deyish). <br/>

                                        9. Savolga yoki xabarga oyatlar bilan bo‘lsa-da, javob berish. <br/>

                                        10. Biror narsani yeb-ichish (labidagi, tishidagi ovqatni ham). <br/>

                                        11. Namozni jamoat bilan o‘qiyotganida yonidagi odamga joy berish uchun
                                        surilish. <br/>

                                        12. Qiroatni Qur’oni Karimga qarab qilish. <br/>

                                        13. «Amali kasir» qilish (ya’ni ko‘rgan odam «Namoz o‘qimayati» deb o‘ylaydigan
                                        darajadagi amallarni qilish). <br/>

                                        14. Najosat ustiga sajda qilish. <br/>

                                        15. Ko‘krakni qibladan boshqa tarafga burish. <br/>

                                        16. Sajda holida ikki oyoq panjalarini yerdan ko‘tarish. <br/>

                                        17. Qiroatni ma’no o‘zgaradigan darajada buzish. <br/>

                                        18. Namoz farzlaridan birortasini uzrsiz qoldirish. <br/>

                                        20. Namozning to‘la bir ruknida avratning ochiq bo‘lishi. <br/>

                                        21. Tahoratning (tayammum, mashning ham) ketishi. <br/>

                                        22. Bomdod namozida quyosh chiqib qolishi. <br/>

                                        23. Namoz asnosida hushidan ketishi.
                                    </p>
                                </div>
                                <div id="namozMakruh">
                                    <h3>Namozdgai makruh amallar</h3>
                                    <p>1. Namoz sunnatlaridan birini atayin tark etish. Masalan, takbiri tahrimadan
                                        keyin sanoni o‘qimaslik, ruku’da, sajdada tasbeh aytmaslik va hokazo. <br/>

                                        2. Mushaf tartibiga teskari qiroat qilish. Masalan, birinchi rakatda Ixlos,
                                        ikkinchi rakatda Masad surasini o‘qish. <br/>

                                        3. Qisqa suralar o‘qiganda orada bir surani tashlab ketish. Misol uchun,
                                        birinchi rakatda Quraysh, ikkinchisida Kavsar surasini o‘qish. <br/>

                                        4. Farz namozlarining bir rakatida bir surani ikki marta o‘qish. <br/>

                                        5. Doim bir surani o‘qish, boshqalarini tark etish (Lekin, “Payg‘ambarimiz
                                        sollallohu alayhi va sallam namozda ko‘proq shu suralarni qiroat qilganlar”,
                                        degan niyati bo‘lsa, makruh emas). <br/>

                                        6. Namozni xushu’siz, beparvo o‘qib qo‘yish. <br/>

                                        7. Namoz o‘qiyotgan odamning sajda qilishga xalaqit bermaydigan mayda toshlarni
                                        surib tashlashi (Hadisda aytilganidek, bir marta sursa bo‘ladi). <br/>

                                        8. Agar sajda o‘rni tuproq bo‘lsa, namozdan chiqqach peshonani tozalab
                                        artish. <br/>

                                        9. Erkaklar sajda qilayotganda ikki bilagini yerga, qornini tizzasiga
                                        tekkazishi. <br/>

                                        10. Erkaklar namoz vaqtida sochni turmaklab olishi. <br/>

                                        11. Qiyomda qo‘lni belga qo‘yish. <br/>

                                        12. Namoz o‘quvchi kiyimini osiltirib yoki yelkasiga tashlab yoxud yig‘ishtirib,
                                        yengini shimarib olishi. <br/>

                                        13. Amali kasir qilmagan holda kiyimni uzrsiz to‘g‘rilash (Jumladan, sajdaga
                                        borayotganda qo‘li bilan shimini ko‘tarish), barmoqlarini bir-birining ichiga
                                        kirgizish, tanasining ayrim joylarini behuda o‘ynash. Chunki bu narsa xushu’ga
                                        xalal beradi. <br/>

                                        14. Dangasalik yoki kibr tufayli bosh kiyimsiz namoz o‘qish (Ammo, Alloh
                                        huzurida o‘zini xor tutish niyatida boshyalang o‘qish makruh emas). <br/>

                                        15. Qorin juda och paytda namoz o‘qish. Bunda avval ozroq taom yeb, nafs
                                        qondiriladi. Keyin xotirjam holda namoz o‘qiladi. <br/>

                                        16. Fikrni chalg‘ituvchi narsalar qarshisida namoz o‘qish. <br/>

                                        17. Hojatxonaga borish zarurati tug‘ilganda yoki yel chiqishi tang qilib
                                        turganda o‘zini majburlab ushlab turish. <br/>

                                        18. Ko‘zini behuda yumib olish. Agar xushu’ hosil qilish uchun yumsa, hechqisi
                                        yo‘q. <br/>

                                        19. Janoza namozida takbir aytilganida boshini ko‘tarish. <br/>

                                        20. Imomning jamoatdan farqli darajada balandda turishi. <br/>

                                        21. Namoz o‘quvchining – safda bo‘sh joy bo‘la turib – keyingi safda
                                        turishi. <br/>

                                        22. Namozxon oldida, yon tarafida, kiyimida, sajdagohida jonli narsalar surati
                                        bo‘lishi (Agar surat orqada bo‘lsa, juda kichik yoki boshi o‘chirilgan bo‘lsa
                                        yoki jonsiz narsalar surati bo‘lsa, zarari yo‘q). <br/>

                                        23. Olovga qarab namoz o‘qish. Chunki bunda namozxon majusiyga o‘xshab qoladi
                                        (Biroq, mehrobda chiroq turishining zarari yo‘q) <br/>

                                        24. Namozxon oldidan birov o‘tishi ehtimoli bor joyda sutra qo‘ymasdan namoz
                                        o‘qish. <br/>

                                        25. Kir, eski ish kiyimida namoz o‘qish. <br/>

                                        26. Namozda oyatlarni, tasbehlarni barmoq bilan sanash. <br/>

                                        <b>Mazkur amallar bilan namoz buzilmaydi, lekin qusurli bo‘lib qoladi. Shuning
                                            uchun namozxon bunday ishlardan saqlangani ma’qul.</b></p>
                                </div>
                            </section>
                            <section style={{display: styleSecond}} className="suralar-global">
                               <div id="fatiha" className="text-center">
                                   <h6>1.Fotiha surasi</h6>
                                   {
                                       fatihas.map((item,index)=>{
                                           const {id,arabic,uzbek,tarjima}=item
                                           return(
                                               <div className="text-center">
                                                   <span><b>{id}</b></span>
                                                   <h2>{arabic}</h2>
                                                   <p className="mb-0"><b>{uzbek}</b></p>
                                                   <p>{tarjima}</p>
                                               </div>
                                           )
                                       })
                                   }
                                   <ReactAudioPlayer
                                       src='audio/fatiha.mp3'
                                       autoPlay
                                       controls
                                   />
                               </div>
                                <div id="fil" className="text-center">
                                    <h6>2.Fil surasi</h6>
                                    {
                                        fils.map((item,index)=>{
                                            const {id,arabic,uzbek,tarjima}=item
                                            return(
                                                <div className="text-center">
                                                    <span><b>{id}</b></span> <br/>
                                                    <img className="w-75 h-75" src={arabic} alt=""/>
                                                    <p className="mb-0"><b>{uzbek}</b></p>
                                                    <p>{tarjima}</p>
                                                </div>
                                            )
                                        })
                                    }
                                    <ReactAudioPlayer
                                        src='audio/fil.mp3'
                                        autoPlay
                                        controls
                                    />
                                </div>
                                <div id="quraysh" className="text-center">
                                    <h6>3.Quraysh surasi</h6>
                                    {
                                        qurayshs.map((item,index)=>{
                                            const {id,arabic,uzbek,tarjima}=item
                                            return(
                                                <div className="text-center">
                                                    <span><b>{id}</b></span> <br/>
                                                    <h2>{arabic}</h2>
                                                    <p className="mb-0"><b>{uzbek}</b></p>
                                                    <p>{tarjima}</p>
                                                </div>
                                            )
                                        })
                                    }
                                    <ReactAudioPlayer
                                        src='audio/quraish.mp3'
                                        autoPlay
                                        controls
                                    />
                                </div>
                                <div id="maun" className="text-center">
                                    <h6>4.Ma'un surasi</h6>
                                    {
                                        mauns.map((item,index)=>{
                                            const {id,arabic,uzbek,tarjima}=item
                                            return(
                                                <div className="text-center">
                                                    <span><b>{id}</b></span> <br/>
                                                    <h2>{arabic}</h2>
                                                    <p className="mb-0"><b>{uzbek}</b></p>
                                                    <p>{tarjima}</p>
                                                </div>
                                            )
                                        })
                                    }
                                    <ReactAudioPlayer
                                        src='audio/maun.mp3'
                                        autoPlay
                                        controls
                                    />
                                </div>
                                <div id="kavsar" className="text-center">
                                    <h6>5.Kavsar surasi</h6>
                                    {
                                        kavsars.map((item,index)=>{
                                            const {id,arabic,uzbek,tarjima}=item
                                            return(
                                                <div className="text-center">
                                                    <span><b>{id}</b></span> <br/>
                                                    <h2>{arabic}</h2>
                                                    <p className="mb-0"><b>{uzbek}</b></p>
                                                    <p>{tarjima}</p>
                                                </div>
                                            )
                                        })
                                    }
                                    <ReactAudioPlayer
                                        src='audio/kavsar.mp3'
                                        autoPlay
                                        controls
                                    />
                                </div>
                                <div id="kafirun" className="text-center">
                                    <h6>6.Kafirun surasi</h6>
                                    {
                                        kafiruns.map((item,index)=>{
                                            const {id,arabic,uzbek,tarjima}=item
                                            return(
                                                <div className="text-center">
                                                    <span><b>{id}</b></span> <br/>
                                                    <h2>{arabic}</h2>
                                                    <p className="mb-0"><b>{uzbek}</b></p>
                                                    <p>{tarjima}</p>
                                                </div>
                                            )
                                        })
                                    }
                                    <ReactAudioPlayer
                                        src='audio/kafirun.mp3'
                                        autoPlay
                                        controls
                                    />
                                </div>
                                <div id="nasr" className="text-center">
                                    <h6>7.Nasr surasi</h6>
                                    {
                                        nasrs.map((item,index)=>{
                                            const {id,arabic,uzbek,tarjima}=item
                                            return(
                                                <div className="text-center">
                                                    <span><b>{id}</b></span> <br/>
                                                    <h2>{arabic}</h2>
                                                    <p className="mb-0"><b>{uzbek}</b></p>
                                                    <p>{tarjima}</p>
                                                </div>
                                            )
                                        })
                                    }
                                    <ReactAudioPlayer
                                        src='audio/nasr.mp3'
                                        autoPlay
                                        controls
                                    />
                                </div>
                                <div id="masad" className="text-center">
                                    <h6>8.Masad surasi</h6>
                                    {
                                        masads.map((item,index)=>{
                                            const {id,arabic,uzbek,tarjima}=item
                                            return(
                                                <div className="text-center">
                                                    <span><b>{id}</b></span> <br/>
                                                    <h2>{arabic}</h2>
                                                    <p className="mb-0"><b>{uzbek}</b></p>
                                                    <p>{tarjima}</p>
                                                </div>
                                            )
                                        })
                                    }
                                    <ReactAudioPlayer
                                        src='audio/masad.mp3'
                                        autoPlay
                                        controls
                                    />
                                </div>
                                <div id="ixlos" className="text-center">
                                    <h6>9.Ixlos surasi</h6>
                                    {
                                        ixloses.map((item,index)=>{
                                            const {id,arabic,uzbek,tarjima}=item
                                            return(
                                                <div className="text-center">
                                                    <span><b>{id}</b></span> <br/>
                                                    <h2>{arabic}</h2>
                                                    <p className="mb-0"><b>{uzbek}</b></p>
                                                    <p>{tarjima}</p>
                                                </div>
                                            )
                                        })
                                    }
                                    <ReactAudioPlayer
                                        src='audio/ixlas.mp3'
                                        autoPlay
                                        controls
                                    />
                                </div>
                                <div id="falaq" className="text-center">
                                    <h6>10.Falaq surasi</h6>
                                    {
                                        falaqs.map((item,index)=>{
                                            const {id,arabic,uzbek,tarjima}=item
                                            return(
                                                <div className="text-center">
                                                    <span><b>{id}</b></span> <br/>
                                                    <h2>{arabic}</h2>
                                                    <p className="mb-0"><b>{uzbek}</b></p>
                                                    <p>{tarjima}</p>
                                                </div>
                                            )
                                        })
                                    }
                                    <ReactAudioPlayer
                                        src='audio/falaq.mp3'
                                        autoPlay
                                        controls
                                    />
                                </div>
                                <div id="nas" className="text-center">
                                    <h6>11.Nas surasi</h6>
                                    {
                                        nases.map((item,index)=>{
                                            const {id,arabic,uzbek,tarjima}=item
                                            return(
                                                <div className="text-center">
                                                    <span><b>{id}</b></span> <br/>
                                                    <h2>{arabic}</h2>
                                                    <p className="mb-0"><b>{uzbek}</b></p>
                                                    <p>{tarjima}</p>
                                                </div>
                                            )
                                        })
                                    }
                                    <ReactAudioPlayer
                                        src='audio/nas.mp3'
                                        autoPlay
                                        controls
                                    />
                                </div>
                            </section>
                            <section style={{display: styleThree}} className="savollar-global">

                                <div id="tahoratSection">
                                    <h3>1.Tahorat haqida</h3>
                                    {
                                        faqs.map((item, index) => {
                                            const {id, savol, javob} = item;
                                            return (
                                                <div>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon/>}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <div className="d-flex flex-column">
                                                                <Typography><h6>{id}.Savol:</h6>{savol}</Typography>
                                                                <Typography></Typography>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography>
                                                                <h6>Javob:</h6> {javob}
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div id="namozSection">
                                    <h3>2.Namoz haqida</h3>

                                        {
                                            namozs.map((item, index) => {
                                                const {id, savol, javob} = item;
                                                return (
                                                    <div>
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon/>}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                <div className="d-flex flex-column">
                                                                    <Typography><h6>{id}.Savol:</h6>{savol}</Typography>
                                                                    <Typography></Typography>
                                                                </div>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Typography>
                                                                    <h6>Javob:</h6> {javob}
                                                                </Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                )
                                            })
                                        }

                                </div>
                            </section>
                        </div>
                    </div>

                </div>
                <div className="row second">
                    <div onClick={function () {
                        setStyle('block')
                        setStyleOne('none')
                        setStyleSecond('none')
                        setStyleThree('none')
                    }} className="col-3">
                        <img src="https://namoz.islom.uz/img/water.svg" alt=""/>
                        <h6>Tahorat</h6>
                    </div>
                    <div onClick={function () {
                        setStyleOne('block')
                        setStyle('none')
                        setStyleSecond('none')
                        setStyleThree('none')

                    }} className="col-3">
                        <img src="https://namoz.islom.uz/img/prayer.svg" alt=""/>
                        <h6>Namoz</h6>
                    </div>
                    <div onClick={function () {
                        setStyleSecond('block')
                        setStyle('none')
                        setStyleOne('none')
                        setStyleThree('none')

                    }} className="col-3">
                        <img src="https://namoz.islom.uz/img/quran.svg" alt=""/>
                        <h6>Suralar</h6>
                    </div>
                    <div onClick={function () {
                        setStyleOne('none')
                        setStyle('none')
                        setStyleSecond('none')
                        setStyleThree('block')

                    }} className="col-3">
                        <img src="https://namoz.islom.uz/img/conversation.svg" alt=""/>
                        <h6>Savollar</h6>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Main;