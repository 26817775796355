const nas=[
    {
        id:1,
        arabic:" قُلْ أَعُوذُ بِرَبِّ النَّاسِ",
        uzbek:"Qul a'uuzu birrobin-naas",
        tarjima:"Ayt: «Odamlar Robbidan panoh soʻrayman..."
    },
    {
        id:2,
        arabic:"مَلِكِ النَّاسِ",
        uzbek:"Malikin-naas",
        tarjima:"odamlar Podshohidan..."
    },
    {
        id:3,
        arabic:" إِلَٰهِ النَّاسِ",
        uzbek:"Ilaahin-naas",
        tarjima:" odamlar Ilohidan..."
    },
    {
        id:4,
        arabic:"مِنْ شَرِّ الْوَسْوَاسِ الْخَنَّاسِ",
        uzbek:"Min sharril vasvaasil xonnas",
        tarjima:"bеrkinib, koʻrinib turuvchi vasvasachining..."
    },
    {
        id:5,
        arabic:" الَّذِي يُوَسْوِسُ فِي صُدُورِ النَّاسِ",
        uzbek:"Allazi yuvasvisu fii suduurin-naas",
        tarjima:"odamlarning koʻkslariga vasvasa soladiganning..."
    },
    {
        id:6,
        arabic:" مِنَ الْجِنَّةِ وَالنَّاسِ",
        uzbek:"Minal jinnati van-naas",
        tarjima:"jinlar va odamlardan boʻlganning yomonligidan (panoh soʻrayman)»."
    }
]
export default nas