const nasr=[
    {
        id:1,
        arabic:"إِذَا جَاءَ نَصْرُ اللَّهِ وَالْفَتْحُ",
        uzbek:"Izaa jaa-a nasrulloohi val fath",
        tarjima:"Vaqtiki, Allohning nusrati va fath kеlsa..."
    },
    {
        id:2,
        arabic:" وَرَأَيْتَ النَّاسَ يَدْخُلُونَ فِي دِينِ اللَّهِ أَفْوَاجًا",
        uzbek:"Va roayatn-naasa yadxuluuna fii diynil-laahi afvaaja",
        tarjima:"va odamlarning Allohning diniga toʻp-toʻp boʻlib kirayotganlarini koʻrsang..."
    },
    {
        id:3,
        arabic:" فَسَبِّحْ بِحَمْدِ رَبِّكَ وَاسْتَغْفِرْهُ ۚ إِنَّهُ كَانَ تَوَّابًا",
        uzbek:"Fasabbih bihamdi Robbika vastag'firhu, innahuu kaana tavvaaba",
        tarjima:"bas, Robbingni hamd ila poklab yod et va Undan magʻfirat soʻra. Albatta, U tavbani koʻplab qabul qiluvchidir."
    }
]
export default nasr