const fil = [
    // {
    //     id: 1,
    //     arabic: "بسم الله الرحمن الرحيم",
    //     uzbek: "Bismillaahir rohmaanir rohiym",
    //     tarjima: "Mehribon va rahmli Allohning nomi bilan boshlayman "
    // },
    {
        id: 1,
        arabic: "https://cdn.shortpixel.ai/spai/w_779+q_lossy+ret_img+to_webp/https://myislam.org/wp-content/uploads/2019/02/Surah-Al-Fil-105-1.jpg",
        uzbek: "A lam taro kayfa fa'la Robbuka bi as'haabil fiyl",
        tarjima: "Robbing fil sohiblarini nima qilganini bilmadingmi?",
    },
    {
        id: 2,
        arabic: "https://cdn.shortpixel.ai/spai/w_579+q_lossy+ret_img+to_webp/https://myislam.org/wp-content/uploads/2019/02/Surah-Al-Fil-105-2.jpg",
        uzbek: "A lam yaj'al kaydahum fii tadiyl",
        tarjima: "Ularning makru hiylasini zoе kеtkazmadimi?",
    },
    {
        id: 3,
        arabic: "https://cdn.shortpixel.ai/spai/w_593+q_lossy+ret_img+to_webp/https://myislam.org/wp-content/uploads/2019/02/Surah-Al-Fil-105-3.jpg",
        uzbek: "Va asrala 'alayhim toyron abaabiyl",
        tarjima: "Va ularning ustiga toʻp-toʻp qushlarni yubormadimi…",
    },
    {
        id:4,
        arabic: "https://cdn.shortpixel.ai/spai/w_609+q_lossy+ret_img+to_webp/https://myislam.org/wp-content/uploads/2019/02/Surah-Al-Fil-105-4.jpg",
        uzbek: "Tarmiyhim bi hijaarotim-min sijjiyl",
        tarjima: " loydan pishirilgan toshlarni otadigan?",
    },
    {
        id: 5,
        arabic: "https://cdn.shortpixel.ai/spai/w_581+q_lossy+ret_img+to_webp/https://myislam.org/wp-content/uploads/2019/02/Surah-Al-Fil-105-5.jpg",
        uzbek: "Faja'alahum ka'asfim ma'kuul",
        tarjima: "Bas, ularni qurt yeb tashlagan oʻsimlikka oʻxshatib yubormadimi?",
    }
]
export default fil