const ixlos=[
    {
        id:1,
        arabic:"قُلْ هُوَ اللَّهُ أَحَدٌ",
        uzbek:"Qul huvalloohu ahad",
        tarjima:"Ayt: «U – Alloh yagonadir."
    },
    {
        id:2,
        arabic:" اللَّهُ الصَّمَدُ",
        uzbek:"Alloohus-somad",
        tarjima:"Alloh Somaddir."
    },
    {
        id:3,
        arabic:" لَمْ يَلِدْ وَلَمْ يُولَدْ",
        uzbek:"Lam yalid va lam yuulad",
        tarjima:" U tugʻmagan va tugʻilmagan."
    },
    {
        id:4,
        arabic:"وَلَمْ يَكُنْ لَهُ كُفُوًا أَحَدٌ",
        uzbek:"Va lam yakullahuu kufuvan ahad",
        tarjima:"Va Unga hеch kim tеng boʻlmagan»."
    }
]
export default ixlos