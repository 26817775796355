const quraysh=[
    {
        id:1,
        arabic:"لِإِيلَافِ قُرَيْشٍ",
        uzbek:"Li iylaafi quroysh",
        tarjima:"Qurayshning lozim tutishi uchun!"
    },
    {
        id: 2,
        arabic: "إِيلَافِهِمْ رِحْلَةَ الشِّتَاءِ وَالصَّيْفِ",
        uzbek: "Iylaafihim rihlatash-shitaai vas-soyf",
        tarjima: "Ularning qish va yoz safarlarini lozim tutishi uchun!",
    },
    {
        id:3 ,
        arabic: "فَلْيَعْبُدُوا رَبَّ هَٰذَا الْبَيْتِ",
        uzbek: "Falya'budu Robba haazal bayt",
        tarjima: "Bas, mana shu Baytning Robbiga ibodat qilsinlar.",
    },
    {
        id: 4,
        arabic: " الَّذِي أَطْعَمَهُمْ مِنْ جُوعٍ وَآمَنَهُمْ مِنْ خَوْفٍ",
        uzbek: "Allazii at'amahum min juu'iv-va aamanahum min xofv",
        tarjima: "U ularni ochlikdan toʻq qilgan, xavfdan omon qilgandir.",
    }
]
export default quraysh