const kafirun=[
    {
        id:1,
        arabic:"قُلْ يَا أَيُّهَا الْكَافِرُونَ",
        uzbek:"Qul yaa ayyuhal kafirun",
        tarjima:". Ayt: «Ey kofirlar..."
    },
    {
        id:2,
        arabic:" لَا أَعْبُدُ مَا تَعْبُدُونَ",
        uzbek:"Laa 'abudu maa ta'budun",
        tarjima:"(Mеn) siz ibodat qilgan narsalarga ibodat qilmasman."
    },
    {
        id:3,
        arabic:" وَلَا أَنْتُمْ عَابِدُونَ مَا أَعْبُدُ",
        uzbek:"Va laa antum 'abiduna maa 'abud",
        tarjima:"Va sizlar ham mеn ibodat qiladigan narsaga ibodat qiluvchimassiz."
    },
    {
        id:4,
        arabic:" وَلَا أَنَا عَابِدٌ مَا عَبَدْتُمْ",
        uzbek:"Va laa ana  'abidum maa 'abattum",
        tarjima:" Va (mеn) siz ibodat qilgan narsaga ibodat qiluvchimasman."
    },
    {
        id:5,
        arabic:" وَلَا أَنْتُمْ عَابِدُونَ مَا أَعْبُدُ",
        uzbek:"Va laa antum 'abiduna maa 'abud",
        tarjima:"Va sizlar ham mеn ibodat qiladigan narsaga ibodat qiluvchimassiz."
    },
    {
        id:6,
        arabic:"لَكُمْ دِينُكُمْ وَلِيَ دِينِ",
        uzbek:"Lakum diynukum va liya diyn",
        tarjima:" Sizga oʻz diningiz va mеnga oʻz dinim»."
    },

]
export default kafirun